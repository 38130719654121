import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import ArrowUpward from '@material-ui/icons/ArrowUpward'

import { useStyles } from './ScrollTopButton.styles'

import { _ } from 'helpers/lang'

export default function ScrollTopButton () {
    const classes = useStyles()

    const handleClick = () => {
        window.scrollTo(0, 0)
    }
    return (
        <IconButton className={classes.root} onClick={handleClick} color="primary" aria-label={_('app.returnTop')}>
            <ArrowUpward />
        </IconButton>
    )
}
