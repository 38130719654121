import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '1000px',
        margin: 'auto',
        padding: theme.spacing(0, 3),
        boxSizing: 'content-box'
    },
    paddingTop: {
        paddingTop: theme.spacing(10),
    },
    paddingBottom: {
        paddingBottom: theme.spacing(10),
    }
}))