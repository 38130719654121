import { alertConstants } from 'modules/alert/alert.constants'

export const alertActions = {
    success,
    error,
    warning,
    info,
    clear,
}

function success(message, options = []) {
    return {
        type: alertConstants.SUCCESS,
        message,
        options,
    }
}

function error(message, options = []) {
    return { type: alertConstants.ERROR, message, options }
}

function warning(message, options = []) {
    return { type: alertConstants.WARNING, message, options }
}

function info(message, options = []) {
    return { type: alertConstants.INFO, message, options }
}

function clear(index) {
    return { type: alertConstants.CLEAR, index }
}
