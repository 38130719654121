import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import { Layout } from 'components/Layout'
import { Link } from 'components/Link'
import { Markdown } from 'components/Markdown'
import { Pageheader } from 'components/Pageheader'
import { Footer } from 'components/Footer'

import { _ } from 'helpers'
import { getPageUrl, getStrategyUrl } from 'utils/functions'
import { convertPageToString, highlight, regexSearch } from 'utils/search'
import { getPages } from 'modules/page/page.selectors'
import { getSetting } from 'modules/setting/setting.selectors'

export const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1, 0, 0),
    },
    result: {
        padding: theme.spacing(1, 0),
    },
    link: {
        display: 'inline-block',
        fontSize: 18,
        margin: '0.5em 0',
    }
}))

const ResultsGroup = props => {
    const { list, getUrl } = props
    const terms = props.terms.split(' ')
    const classes = useStyles(props)
    return list.map((item, i) => <div className={classes.result} key={i}>
        <Link className={classes.link} href={getUrl(item)}>{item.name}</Link>
        <Markdown source={highlight(convertPageToString(item), terms)} />
    </div>)
}

const Results = ({ pages, strategies, terms }) => {
    const classes = useStyles()

    return <div className={classes.root}>
        {pages.length > 0 && <ResultsGroup list={pages} getUrl={getPageUrl} terms={terms} />}
        {strategies.length > 0 && <ResultsGroup list={strategies} getUrl={getStrategyUrl} terms={terms} />}
    </div>
}

class SearchResultsComponent extends React.Component {

    render() {
        const { value, selectedPages, selectedStrategies, settings } = this.props

        const noResults =
            selectedPages.length === 0 && 
            selectedStrategies.length === 0
            
        const title = noResults
            ? _('app.search_noresult', { value })
            : _('app.search_results', { value })
        
        return (
            <Layout title={value} container={true}>
                <Pageheader title={title} />
                {noResults
                    ? null
                    : <Results
                        pages={selectedPages}
                        strategies={selectedStrategies}
                        terms={value}
                    />
                }
                <Footer content={getSetting(settings, 'footer_text')} />
            </Layout>
        )
    }
}

function mapStateToProps(state, { match }) {
    const { value, settings } = match.params

    const pages = getPages(state.pages)
    const strategies = getPages(state.strategies)

    return {
        value,
        settings,
        selectedPages: regexSearch(pages, value),
        selectedStrategies: regexSearch(strategies, value),
    }
}

export const SearchResults = connect(mapStateToProps)(SearchResultsComponent)
