
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        border: '1px solid #E0E0E0',
        borderTop: 'none',
        zIndex: 10,
    },
    content: {
        padding: theme.spacing(2),
    },
    title: {
        padding: theme.spacing(3),
        position: 'relative',
        background: 'rgba(9, 87, 151, 0.07)',
        paddingLeft: '3.5em',
    },
    picto: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left:  theme.spacing(1),
        margin: 'auto',
        height: '1.7em',
        width: '1.7em',
        padding: theme.spacing(1.2),
        background: 'white',
        borderRadius: '100%',
    },
    button: {
        position: 'absolute',
        top: '-1.5rem',
        right: '1rem',
        zIndex: 9,
        background: 'white',
        border: '2px solid #fff',
        borderColor: theme.palette.primary.main,

        '&:hover': {
            background: 'white',
        }
    }
}))
