import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        display: 'flex',
        boxSizing: 'content-box',
        alignItems: 'center',
        justifyContent: 'center',
        top: '-2rem',
        left: 0,
        right: 0,
        width: '3rem',
        height: '3rem',
        margin: 'auto',
        border: '0.5rem solid white',
        borderRadius: 100,
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: 25,
        fontWeight: 'bold',
        background: 'white',
        zIndex: 10,
        '&--blue': {
            background: theme.palette.blue.main,
        },
        '&--cyan': {
            background: theme.palette.cyan.main,
        },
        '&--yellow': {
            background: theme.palette.yellow.main,
        },
        '&--red': {
            background: theme.palette.red.main,
        },
        '&--green': {
            background: theme.palette.green.main,
        },
        '&--pink': {
            background: theme.palette.pink.main,
        },
    },
}))