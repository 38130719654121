import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { Home } from 'app/Home'
import { NotFound } from 'app/NotFound'
import { SearchResults } from 'app/SearchResults'
import { Strategy } from 'app/Strategy'
import { Page } from 'app/Page'
import { getLang } from 'helpers/lang'
import { url } from 'helpers/urls'

export const Routes = () => (
    <>
        <Route
            component={({ match, location }) => {
                const needle = "/app";
                const index = location.pathname.indexOf(needle);

                if (index !== -1) {
                    // l'URL contient 'app', soit la structure URL originale avant la traduction.
                    const path = url(location.pathname.substring(index + needle.length))
                        .replace("//", "/");
                    return <Redirect to={path} />;
                }

                return null;
            }}
        />
        <Switch>
            <Route exact path="/" component={() => <Redirect to={getLang()} />} />
            <Route exact path="/:locale" component={Home} />
            <Route exact path="/:locale/not-found" component={NotFound} />
            <Route exact path="/:locale/p/:slug" component={Page} />
            <Route exact path="/:locale/s/:slug" component={Strategy} />
            <Route exact path="/:locale/search/:value" component={SearchResults} />
            <Route component={() => <Redirect to={url("not-found")} />} />
        </Switch>
    </>
)
