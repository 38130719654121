import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        fontWeight: '600',
        color: theme.palette.primary.main,
        textDecoration: 'none',
    },
    button: {
        display: 'inline-block',
        boxSizing:' border-box',
        minWidth: '8rem',
        margin: theme.spacing(0, 1, 1, 0),
        padding: theme.spacing(1, 2),

        borderColor: 'rgba(9, 87, 151, 0.5)',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderRadius: 100,
        
        textAlign: 'center',
        // fontSize: '14px',
        fontWeight: 500,
        lineHeight: 1.75,
        color: theme.palette.primary.main,
        textDecoration: 'none',
        textTransform: 'none',

        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        
        '&:hover': {
            borderWidth: '2px',
            borderColor: theme.palette.primary.main,
            backgroundColor: 'rgba(9, 87, 151, 0.04)',
        }
    },
    icon: {
        fontSize: '1.5em',
        verticalAlign: 'text-bottom',
        margin: theme.spacing(0, 0.25),
    }
}))