import i18next from 'i18next'
// import moment from 'moment'
// import 'moment/locale/fr'

import config from 'config'
import fr from 'lang/fr'
import en from 'lang/en'

const regex = /^(fr|en)$/g

/* Language data & i18n settings */
export const defaultLanguage = 'fr'

export const getLang = () => {
    // get code from first param in url if it matches
    const urlParams = window.location.pathname.split('/')
    const matches = urlParams[1].match(regex)

    const lang = matches ? matches[0] : getBrowserLang()

    return lang
}

export const languages = { fr, en } // { fr, en }

export const options = {
    // fallbackLng: defaultLanguage,
    debug: config.debugMode,
    resources: languages,
}

/* Functions */

function init(lang) {
    window.i18next = i18next
    window.i18next.init(options, () => changeLang(lang))
}

function changeLang(lang = null) {
    if (lang === null) {
        lang = getLang()
    }

    if (lang !== window.i18next.language) {
        window.i18next.changeLanguage(lang)
    } else if (options.debug) {
        console.log('Tried changing language but it is already set to ' + lang)
    }
}

export const _ = (keys, options) => i18next.t(keys, options)

export const exists = (keys, options) => i18next.exists(keys, options)

export const getBrowserLang = () => {
    const lang = window.navigator.language.split('-')[0]

    if (regex.test(lang)) {
        return lang
    } else {
        return defaultLanguage
    }
}

export const Lang = {
    languages,
    options,
    changeLang,
    getLang,
    exists,
    _,
}

init(getLang())
