import React from 'react'
export default props => ( 
    <svg
        width="197" height="71" viewBox="0 0 197 71" fill="none" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M53.79 47.121V30.77H53.666C53.086 31.305 50.937 31.348 50.026 31.348H48.446V31.481C50.271 32.602 50.023 34.258 50.023 36.317L50.025 43.238C50.025 46.696 46.627 49.124 43.888 49.124C40.28 49.124 38.782 46.562 38.782 42.427V30.771H38.658C38.077 31.305 35.915 31.349 35.004 31.349H33.437V31.482C35.263 32.603 35.014 34.259 35.014 36.319V43.215C35.014 49.528 37.752 52.527 42.644 52.527C45.549 52.527 48.531 51.496 50.025 49.035V52.018H55.369V51.883C53.543 50.85 53.79 49.187 53.79 47.121Z" fill="black"/>
        <path d="M62.077 38.378C62.576 35.57 64.65 33.787 67.432 33.787C70.42 33.787 72.328 35.258 72.784 38.378H62.077ZM77.143 40.984C77.308 35.213 73.448 30.859 67.805 30.859C61.621 30.859 57.433 35.346 57.433 41.569C57.433 47.839 62.285 52.615 69.341 52.615C71.292 52.615 73.283 52.305 75.068 51.506L77.143 47.297H77.017C75.108 48.92 72.702 49.688 70.211 49.688C65.522 49.688 61.995 46.486 61.912 40.984H77.143Z" fill="black"/>
        <path d="M80.435 21.273C81.428 21.273 83.457 21.211 84.069 20.607H84.193V48.923C85.307 49.235 86.462 49.458 87.616 49.458C92.151 49.458 94.748 46.287 94.748 41.866C94.748 37.532 92.728 34.183 88.565 34.183C87.246 34.183 86.215 34.54 85.101 35.166L87.328 31.354C88.275 30.996 89.265 30.86 90.255 30.86C94.957 30.86 98.955 34.942 98.955 41.062C98.955 48.074 94.212 52.608 86.709 52.608C83.905 52.608 81.214 52.114 79.182 51.799V51.662C80.551 51.033 80.427 49.548 80.427 48.074V27.49C80.427 25.423 80.675 22.53 78.892 21.406V21.273H80.435Z" fill="black"/>
        <path d="M106.321 38.378C106.821 35.57 108.894 33.787 111.676 33.787C114.663 33.787 116.572 35.258 117.029 38.378H106.321ZM121.385 40.984C121.553 35.213 117.693 30.859 112.049 30.859C105.866 30.859 101.676 35.346 101.676 41.569C101.676 47.839 106.53 52.615 113.584 52.615C115.535 52.615 117.528 52.305 119.311 51.506L121.386 47.297H121.263C119.354 48.92 116.946 49.688 114.456 49.688C109.766 49.688 106.24 46.486 106.156 40.984H121.385Z" fill="black"/>
        <path d="M139.945 35.989H139.822C138.618 34.505 136.625 33.787 134.757 33.787C130.732 33.787 127.829 36.843 127.829 41.11C127.829 46.275 131.853 49.689 136.459 49.689C138.575 49.689 140.9 48.79 142.518 47.488H142.643L140.485 51.728C138.742 52.439 136.834 52.616 135.006 52.616C128.617 52.616 123.622 48.117 123.622 42.278C123.622 34.552 129.198 30.86 135.463 30.86C136.958 30.86 138.493 31.037 139.945 31.392V35.989Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M71.9649 27.154C71.9649 25.066 71.9649 22.661 71.9649 22.661C69.2449 23.88 66.7209 26.16 65.0339 28.378V30.128C69.0699 27.407 71.9649 27.28 71.9649 27.154Z" fill="#1D1D1B"/>
        <path d="M16.357 49.526C10.091 49.526 5.732 42.456 5.732 36.45C5.732 36.389 5.732 35.834 5.733 35.774C5.811 29.956 9.577 24.498 16.15 24.498C23.066 24.498 26.633 31.041 26.691 37.241C26.691 37.291 26.691 37.837 26.691 37.887C26.691 44.493 22.583 49.526 16.357 49.526ZM37.837 56.398C33.21 57.155 28.762 55.697 24.035 50.289C28.601 47.997 31.424 42.291 31.424 36.988C31.424 36.934 31.423 36.384 31.423 36.329C31.341 27.255 24.525 21.264 16.399 21.264C8.257 21.264 1.062 27.5 1.001 36.646C1.001 36.687 1 37.219 1 37.258C1 46.155 7.601 52.761 15.694 52.761C16.857 52.761 17.976 52.581 19.097 52.311C24.398 57.276 29.122 59.39 33.205 58.898C35.142 58.665 37.187 57.896 38.416 56.299L37.837 56.398Z" fill="black"/>
        <path d="M170.306 21.278H150.064V33.685H170.306V21.278Z" fill="#095797"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M160.186 21.928C160.039 22.2 159.708 22.59 159.153 23.291C158.506 24.095 158.619 25.172 158.926 25.806C159.277 26.54 159.479 27.148 159.64 27.726C159.862 28.566 159.715 29.688 159.73 29.586H159.171C159.171 28.217 158.742 26.929 157.711 26.357C156.243 25.544 154.794 26.953 155.254 28.418C155.498 29.187 155.93 29.445 156.052 29.445C156.052 29.445 155.85 28.598 156.462 28.167C157.038 27.759 157.749 28.005 158.079 28.492C158.391 28.952 158.44 29.586 158.44 29.586H157.387V30.366L159.508 30.364C159.508 30.364 159.45 31.467 158.29 31.36C158.29 31.36 158.434 32.227 159.508 31.774C159.508 31.774 159.45 32.681 160.188 33.138C160.924 32.681 160.871 31.774 160.871 31.774C161.947 32.226 162.088 31.36 162.088 31.36C160.925 31.468 160.871 30.364 160.871 30.364L162.992 30.366V29.586H161.935C161.935 29.586 161.986 28.953 162.297 28.492C162.626 28.005 163.339 27.759 163.912 28.167C164.524 28.598 164.325 29.445 164.325 29.445C164.448 29.445 164.878 29.187 165.123 28.418C165.583 26.953 164.132 25.544 162.668 26.357C161.632 26.929 161.209 28.217 161.209 29.586H160.646C160.66 29.688 160.513 28.566 160.737 27.726C160.895 27.148 161.099 26.54 161.45 25.806C161.757 25.171 161.871 24.095 161.224 23.291C160.669 22.59 160.335 22.2 160.186 21.928Z" fill="white"/>
        <path d="M196.454 21.278H176.206V33.685H196.454V21.278Z" fill="#095797"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M186.329 21.928C186.178 22.2 185.848 22.59 185.288 23.291C184.641 24.095 184.756 25.172 185.065 25.806C185.417 26.54 185.619 27.148 185.779 27.726C185.999 28.566 185.855 29.688 185.87 29.586H185.307C185.307 28.217 184.882 26.929 183.847 26.357C182.381 25.544 180.934 26.953 181.394 28.418C181.635 29.187 182.066 29.445 182.19 29.445C182.19 29.445 181.99 28.598 182.603 28.167C183.175 27.759 183.887 28.005 184.216 28.492C184.53 28.952 184.58 29.586 184.58 29.586H183.526V30.366L185.643 30.364C185.643 30.364 185.586 31.467 184.427 31.36C184.427 31.36 184.569 32.227 185.643 31.774C185.643 31.774 185.586 32.681 186.329 33.138C187.062 32.681 187.005 31.774 187.005 31.774C188.082 32.226 188.226 31.36 188.226 31.36C187.061 31.468 187.005 30.364 187.005 30.364L189.129 30.366V29.586H188.068C188.068 29.586 188.123 28.953 188.432 28.492C188.764 28.005 189.475 27.759 190.052 28.167C190.659 28.598 190.467 29.445 190.467 29.445C190.585 29.445 191.015 29.187 191.253 28.418C191.714 26.953 190.265 25.544 188.801 26.357C187.77 26.929 187.344 28.217 187.344 29.586H186.785C186.8 29.688 186.65 28.566 186.875 27.726C187.029 27.148 187.236 26.54 187.586 25.806C187.891 25.171 188.004 24.095 187.359 23.291C186.807 22.59 186.47 22.2 186.329 21.928Z" fill="white"/>
        <path d="M170.306 39.595H150.064V51.999H170.306V39.595Z" fill="#095797"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M160.186 40.242C160.039 40.515 159.708 40.905 159.153 41.604C158.506 42.408 158.619 43.483 158.926 44.119C159.277 44.854 159.479 45.46 159.64 46.043C159.862 46.879 159.715 48.001 159.73 47.899H159.171C159.171 46.533 158.742 45.241 157.711 44.669C156.243 43.855 154.794 45.268 155.254 46.73C155.498 47.498 155.93 47.759 156.052 47.759C156.052 47.759 155.85 46.913 156.462 46.48C157.038 46.075 157.749 46.321 158.079 46.8C158.391 47.265 158.44 47.898 158.44 47.898H157.387V48.677H159.508C159.508 48.677 159.45 49.777 158.29 49.673C158.29 49.673 158.434 50.538 159.508 50.082C159.508 50.082 159.45 50.994 160.188 51.451C160.924 50.994 160.871 50.082 160.871 50.082C161.947 50.538 162.088 49.673 162.088 49.673C160.925 49.777 160.871 48.677 160.871 48.677H162.992V47.898H161.935C161.935 47.898 161.986 47.265 162.297 46.8C162.626 46.321 163.339 46.075 163.912 46.48C164.524 46.913 164.325 47.759 164.325 47.759C164.448 47.759 164.878 47.498 165.123 46.73C165.583 45.267 164.132 43.854 162.668 44.669C161.632 45.242 161.209 46.533 161.209 47.899H160.646C160.66 48.001 160.513 46.879 160.737 46.043C160.895 45.46 161.099 44.854 161.45 44.119C161.757 43.483 161.871 42.408 161.224 41.604C160.669 40.906 160.335 40.516 160.186 40.242Z" fill="white"/>
        <path d="M196.454 39.595H176.206V51.999H196.454V39.595Z" fill="#095797"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M186.329 40.242C186.178 40.515 185.848 40.905 185.288 41.604C184.641 42.408 184.756 43.483 185.065 44.119C185.417 44.854 185.619 45.46 185.779 46.043C185.999 46.879 185.855 48.001 185.87 47.899H185.307C185.307 46.533 184.882 45.241 183.847 44.669C182.381 43.855 180.934 45.268 181.394 46.73C181.635 47.498 182.066 47.759 182.19 47.759C182.19 47.759 181.99 46.913 182.603 46.48C183.175 46.075 183.887 46.321 184.216 46.8C184.53 47.265 184.58 47.898 184.58 47.898H183.526V48.677H185.643C185.643 48.677 185.586 49.777 184.427 49.673C184.427 49.673 184.569 50.538 185.643 50.082C185.643 50.082 185.586 50.994 186.329 51.451C187.062 50.994 187.005 50.082 187.005 50.082C188.082 50.538 188.226 49.673 188.226 49.673C187.061 49.777 187.005 48.677 187.005 48.677H189.129V47.898H188.068C188.068 47.898 188.123 47.265 188.432 46.8C188.764 46.321 189.475 46.075 190.052 46.48C190.659 46.913 190.467 47.759 190.467 47.759C190.585 47.759 191.015 47.498 191.253 46.73C191.714 45.267 190.265 43.854 188.801 44.669C187.77 45.242 187.344 46.533 187.344 47.899H186.785C186.8 48.001 186.65 46.879 186.875 46.043C187.029 45.46 187.236 44.854 187.586 44.119C187.891 43.483 188.004 42.408 187.359 41.604C186.807 40.906 186.47 40.516 186.329 40.242Z" fill="white"/>
    </svg>
)
