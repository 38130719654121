import React from 'react'
import MarkdownComponent from 'react-markdown'
import htmlParser from 'react-markdown/plugins/html-parser'
import { makeStyles } from '@material-ui/core/styles'

import { Link } from 'components/Link'
import { Image } from 'components/Image'
import { Definition } from 'components/Definition'
import './Markdown.css'

const useStyles = makeStyles(theme => ({
    span: {
        color: theme.palette.primary.main,
        background: theme.palette.primary.light,
        cursor: 'pointer',
        position: 'relative',
    },
}))

function getCoreProps(props) {
    return props['data-sourcepos'] ? {
        'data-sourcepos': props['data-sourcepos']
    } : {}
}

const parseHtml = htmlParser({
    isValidNode: node => {
        return ['tag', 'text'].includes(node.type)
    },
})

const LinkRenderer = props => {
    const { value } = props.children[0].props
    const buttonMatch = value.match(/^\[.*\]$/)
    if (buttonMatch) {
        return <Link {...props} button>{value.slice(1,-1)}</Link>
    } 

    return <Link {...props} />
}

const ImageRenderer = props => <Image {...props} />

const EmphasisRenderer = ({ children }) => {
    let { value } = children[0].props
    const classes = useStyles()
    
    if (value.includes('--')) {
        value = value.split('--')
        const title = value[0].trim()

        const TriggerComponent = props => <span className={classes.span} {...props}>{title}</span>

        return <Definition title={title} TriggerComponent={TriggerComponent} mouseover><p>{value[1]}</p></Definition>
    }
    
    return <em>{children}</em>
}

const TableCellRenderer = (props) => {
    let coreProps = getCoreProps(props)

    const style = props.align ? {
        textAlign: props.align
    } : undefined

    coreProps = style ? {
        ...coreProps,
        style: style
    } : coreProps

    let colspan = false

    let children = props.children 

    if (children[0]) {
        const { value } = children[0].props
        if (value) {
            const stringStart = value.substring(0, 8)
            if (stringStart.includes('@cols=')) {
                colspan = parseInt(stringStart.replace('@cols=', '').replace(':', ''))
                children[0] = children[0].props.value.replace(stringStart, '')
            }
        }
        
    }


    if (props.isHeader)
        return <th {...coreProps} colSpan={colspan || null}>{children}</th>


    return <td {...coreProps} colSpan={colspan || null}>{children}</td>
}

const renderers = {
    image: ImageRenderer,
    link: LinkRenderer,
    emphasis: EmphasisRenderer,
    tableCell: TableCellRenderer,
}

export const Markdown = props => (
    <div className="markdown">
        <MarkdownComponent
            escapeHtml={false}
            astPlugins={[parseHtml]}
            renderers={renderers}
            {...props}
        />
    </div>
)
    