import React, { useRef } from 'react'
import { Formik, Form } from 'formik'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'

import { _, useHistory } from 'helpers'

import { useStyles } from './SearchInput.styles'
import { url } from '../../helpers/urls'


const validate = values => {
    let errors = {}

    if (!values.term.trim() || values.term.trim() === '') {
        errors.term = true
    }
    return errors
}

const submit = (ref, history) => ({ term }, { setSubmitting }) => {
    ref.current.firstChild.blur()
    setSubmitting(false)
    history.push(url('search', term.toLowerCase()))
}

export const SearchInput = ({ handleFocus }) => {
    const history = useHistory()
    const classes = useStyles()
    const ref = useRef()

    return (
        <Formik
            initialValues={{ term: '' }}
            validate={validate}
            onSubmit={submit(ref, history)}
        >
            {({ values, handleChange }) => {

                return (
                    <Form>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                value={values.term}
                                ref={ref}
                                name="term"
                                onChange={handleChange}
                                onFocus={() => handleFocus(true)}
                                onBlur={() => handleFocus(false)}
                                inputProps={{ 'aria-label': 'Recherche' }}
                                placeholder={_('app.search')}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                            />
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

