import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'

import { initSentry } from './initSentry'
import { _, isOnline, Lang } from 'helpers'

import { Pageheader } from 'components/Pageheader'
import { Button, ButtonList } from 'components/Button'


export const ErrorBoundary = class extends Component {
    constructor(props) {
        super(props)
        this.state = { eventId: null }        
        initSentry()
    }
    
    static getDerivedStateFromError(error) {
        return { hasError: true }
    }
    
    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtra('isOnline', isOnline())
            scope.setExtra('updated_at', localStorage.getItem('updated_at'))
            scope.setExtras(errorInfo)
            const eventId = Sentry.captureException(error)
            this.setState({eventId})
        })
    }

    renderErrorPage() {
        return (
            <div>
                <Pageheader title={_('common.reportTitle')} smalltitle={_('common.reportMessage')} />
                <ButtonList>
                    <Button 
                        label={_('common.reportLink')}
                        onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId, lang: Lang.getLang() })}
                    />
                </ButtonList>
            </div>
        )
    }

    render() {
        // if (this.state.hasError) {
        //     //render fallback UI
        //     return this.renderErrorPage()
        // }
            
        //when there's not an error, render children untouched
        return this.props.children
    }
}
