import { makeStyles } from '@material-ui/core/styles'
import { getColor } from 'utils/theme'


export const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        flexGrow: 1,
        width: '100%',
        zIndex: 20,
    },
    homeTab: {
        minWidth: '80px',
    },
    activeTab: {
        background: ({ active }) => theme.palette[getColor(active)].tab
    }
}))
 