import { uiConstants } from 'modules/ui/ui.constants'

export const uiActions = {
    toggleMenu,
}

function toggleMenu(payload) {
    return dispatch => {
        dispatch(action(payload))
    }

    function action(payload) {
        return { type: uiConstants.TOGGLEMENU, payload }
    }

}
