import { settingConstants } from 'modules/setting/setting.constants' // _constants

const storedData = JSON.parse(localStorage.getItem('settings'))
const initialState = storedData ? [ ...storedData ] : []

// all state changes manipulations happen here
export const settingState = function(state = initialState, { type, payload }) {
    switch (type) {
        case settingConstants.LIST_SUCCESS:
            return payload.data

        default:
            return state
    }
}
