import React from 'react'
import CardComponent from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Link } from 'react-router-dom'

import { useStyles } from './Card.styles'
import { appUrls } from 'helpers/urls'
import { NumberTag } from 'components/NumberTag'

export const Card = ({ number, color = 'primary', children, post, ...rest }) => {
    const classes = useStyles()

    return (
        <CardComponent variant="outlined" className={`${classes.root} ${classes.root}--${color}`}>
            <Link to={appUrls.strategy(post.slug)} className={classes.link}>
                <NumberTag color={color} number={number} />
                <CardContent className={classes.cardContent}>{children}</CardContent>
                <div className={classes.border} />
            </Link>
        </CardComponent>
    )
}
