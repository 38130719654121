import * as Sentry from '@sentry/browser'

export const sentryMiddleware = store => next => action => {
    try {
        const { type } = action
        Sentry.addBreadcrumb({
            message: type,
            category: 'redux action',
            level: 'info',
        })
        return next(action)
    } catch (error) {
        Sentry.withScope(scope => {
            scope.setTag('source', 'middleware')
            scope.setExtra('action', action)
            // scope.setExtra('state', store.getState()) // too heavy for sentry
            Sentry.captureException(error)
        })

        throw error
    }
}
