import React, { useState } from 'react'
import { withRouter } from "react-router"
import { connect } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import HomeIcon from '@material-ui/icons/Home'

import { useStyles } from './Bottombar.styles'
import { useHistory } from 'helpers'
import { getStrategies, getStrategyIndex } from 'modules/strategy/strategy.selectors'
import { getStrategyUrl } from 'utils/functions'

import { _ } from 'helpers/lang'
import { url } from 'helpers/urls'

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
    }
}

const BottombarFunction = (props) => {
    const {
        tabs,
        selected,
    } = props

    const [value, setValue] = useState(selected) 
    const classes = useStyles({ active: value - 1 })
    const history = useHistory()

    const handleChange = (event, newValue) => {
        if (newValue === 0) {
            history.push(url())
        } else {
            
            history.push(getStrategyUrl(tabs[newValue - 1])) // removing home tab
        }
        setValue(newValue)
    }

    return (
        <div className={classes.container}>
            <AppBar
                position="static"
                color="primary"
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    aria-label={_("app.strategie")}
                >
                    <Tab key="home" classes={{ root: classes.homeTab }} label={<HomeIcon />} {...a11yProps("home")} />
                    {tabs.map((tab, i) => {
                        const label = tab.short_title
                        return (
                            <Tab
                                key={tab.id}
                                label={label}
                                classes={{ root: value - 1 === i ? classes.activeTab : '' }}
                                {...a11yProps(tab.id)}
                            />
                        )
                    })}
                </Tabs>
            </AppBar>
        </div>
    )
}

class BottombarWrapper extends React.Component {
    render() {
        return <BottombarFunction {...this.props} />
    }
}

function mapStateToProps(state, { match, location }) {
    const { slug } = match.params

    const strategies = getStrategies(state.strategies)    
    const index = getStrategyIndex(state.strategies, slug)
    // const post = getStrategy(state.strategies, slug)

    // const hasPost = post && post.length > 0
    
    // const selectedPost = hasPost ? post[0] : false
    const selected = index >= 0
        ? index + 1 // +1 to offset because we add a home tab
        : 0

    return {
        tabs: strategies,
        selected,
    }
}

export const Bottombar = withRouter(connect(mapStateToProps)(BottombarWrapper))
