import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'

import { getLang } from 'helpers/lang'

export const getStrategies = strategiesState => strategiesState.data.filter(p => p.lang === getLang())

export const getLoaded = strategiesState => Boolean(strategiesState.loaded)

export const getStrategy = (strategiesState, slug) => {
    const data = getStrategies(strategiesState)
    if (isEmpty(data)) return null

    const result = values(data).filter(strategy => strategy.slug === slug)
    return result
}

export const getStrategyIndex = (strategiesState, slug) => {
    const data = getStrategies(strategiesState)
    let index = -1

    if (isEmpty(data)) return index

    data.forEach((s, i) => {
        if (s.slug === slug) {
            index = i
        }   
    })

    return index
}


