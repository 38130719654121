import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    wrapper: {
        flexGrow: 1,
    },
    root: {
        background: '#FFF',
        color: 'black',
        height: '80px',
        borderTop: `8px solid ${theme.palette.primary.main}`,
        [theme.breakpoints.down('sm')]: {
            height: '72px',
            borderTop: 'none',
        },
    },

    toolbar: {
        width: '100%',
        margin: 'auto',
        maxWidth: '1048px',
        boxSizing: 'border-box',
    },

    menuButton: {
        marginLeft: theme.spacing(1),
    },
    menuButtonLabel: {  
        margin: '1px 4px 0 0',    
    },

    titleContainer: {
        display: 'block',
        flexGrow: 1,
    },
    title: {
        maxWidth: 500,
        lineHeight: '1.2em',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    'title-responsive': {
        display: 'none',
        lineHeight: '1.2em',
        padding: theme.spacing(1, 3, 2),
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    filterTitle: {
        display: 'flex',
        alignItems: 'center',
        margin: '1.5em 0',
        // textTransform: 'uppercase',
    },
    filterIcon: {
        fontSize: '1.1em',
        marginRight: theme.spacing(0.5),
    },
    drawer: {
        width: 350,
        height: '100%',
        maxWidth: '100vw',
        position: 'relative',
        paddingBottom: theme.spacing(8),
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 2),
        justifyContent: 'flex-end',
    },
    drawerContent: {
        padding: theme.spacing(2),
    },
    listRoot: {
        background: theme.palette.primary.main,
        padding: 0,
    },
    listItem: {
        '&:not(:last-child)': {
            borderBottom: `2px solid rgba(255,255,255,.2)`,
        }
    },
    labelRoot: {
        marginLeft: 0,
        width: '100%',
        justifyContent: 'space-between',
    },
    label: {
        // fontWeight: 'bold',
        margin: theme.spacing(1.5, 0),
    },
    uncheckedSwitch: {
        opacity: .5,
    },
    track: {
        background: 'white'
    },
    drawerFooter: {
        position: 'absolute',
        left: '0px',
        bottom: '0px',
        padding: theme.spacing(1),
    },
    
    logo: {
        marginRight: theme.spacing(4),
        minWidth: '120px',
    },

    paper: {
        background: theme.palette.primary.dark,
        color: 'white',
    },

    icon: {
        color: 'white',
    },

    mask: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        background: 'white',
        transition: theme.transitions.create('opacity'),
        '&.active': {
            opacity: 1,
        },
        '&.inactive': {
            opacity: 0,
            pointerEvents: 'none',
        },
    },
}))
