import React from 'react'
import desktopImg from 'assets/img/header-desktop.jpg'
import mobileImg from 'assets/img/header-mobile.jpg'

import { useStyles } from './ImageHeader.styles'
import { Responsive } from 'components/Responsive/index'
import { fixMediaUrls } from 'utils/functions'



export const ImageHeader = () => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Responsive size='desktop'>
                <img className={classes.img} src={fixMediaUrls(desktopImg)} alt="Entête"/>
            </Responsive>

            <Responsive size='mobile'>
                <img className={classes.img} src={fixMediaUrls(mobileImg)} alt="Entête"/>
            </Responsive>
        </div>
    )
}