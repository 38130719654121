import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import FilterIcon from '@material-ui/icons/FilterList'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import SwitchComponent from '@material-ui/core/Switch'

import { filterActions } from 'modules/filter/filter.actions'
import { uiActions } from 'modules/ui/ui.actions'
import { getSetting } from 'modules/setting/setting.selectors'
import { getPages } from 'modules/page/page.selectors'
import { useHistory } from 'helpers'
import { getPageUrl } from 'utils/functions'

import { SearchInput } from 'components/SearchInput'
import Logo from 'components/Icon/Logo'
import { ElevationScroll } from './Topbar.helpers'
import { useStyles } from './Topbar.styles'

import { getLang, _ } from 'helpers/lang'
import { langSwitcherUrl, url } from 'helpers/urls'

// const ListItemLink = props => <ListItem button component="a" {...props} />

const Pages = ({ pages, handleClick }) => {
    const history = useHistory()
    const classes = useStyles()

    return (
        <>
            <Divider />
            <List classes={{ root: classes.listRoot }}>
                {pages.map((page, key) => (
                    <ListItem
                        classes={{ root: classes.listItem }}
                        key={key}
                        button
                        onClick={(e) => {
                            handleClick(e)
                            history.push(getPageUrl(page))
                        }}
                    >
                        <ListItemText primary={page.name} />
                    </ListItem>
                ))}
                <ListItem
                    classes={{ root: classes.listItem }}
                    key="contact"
                    button
                    component={HashLink}
                    to="/#contact"
                    onClick={(e) => handleClick(e)}
                >
                    <ListItemText primary={_('app.contact')} />
                </ListItem>
                <LangSwitcher />
            </List>
        </>
    )
}

const LangSwitcher = () => {
    const classes = useStyles()

    const lang = getLang() === 'fr' ? 'en' : 'fr'

    return (
        <ListItem
            classes={{ root: classes.listItem }}
            button
            onClick={(e) => {
                window.location.href = langSwitcherUrl()
            }}
        >
            <ListItemText primary={_(`app.${lang}`)} />
        </ListItem>
    )
}

class Menu extends React.Component {
    handleSwitchChange = (event) =>
        this.props.updateFilters({ [event.target.name]: event.target.checked })

    Switch = ({ label, name }) => (
        <FormGroup row>
            <FormControlLabel
                classes={{
                    root: this.props.classes.labelRoot,
                    label: this.props.classes.label,
                }}
                labelPlacement="start"
                control={
                    <SwitchComponent
                        name={name}
                        onChange={this.handleSwitchChange}
                        checked={this.props.filters[name]}
                        color="default"
                        classes={{
                            track: this.props.classes.track,
                            root:
                                !this.props.filters[name] &&
                                this.props.classes.uncheckedSwitch,
                        }}
                    />
                }
                label={label}
            />
        </FormGroup>
    )

    render() {
        const { classes, open, updateMenu, pages } = this.props

        return (
            <Drawer
                classes={{ paper: classes.paper }}
                open={open}
                onClose={updateMenu(false)}
            >
                <div role="presentation" className={classes.drawer}>
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={updateMenu(false)}>
                            <ChevronLeftIcon
                                className={classes.icon}
                                fontSize="large"
                            />
                        </IconButton>
                    </div>

                    <Pages pages={pages} handleClick={updateMenu(false)} />
                    <Divider />

                    <div className={classes.drawerContent}>
                        <div className={classes.filterTitle}>
                            <FilterIcon className={classes.filterIcon} />{' '}
                            <span>{_('app.filters')} : </span>
                        </div>

                        <this.Switch label={_('app.agee')} name="agee" />
                        <this.Switch label={_('app.lgbtq')} name="lgbtq" />
                        <this.Switch label={_('app.homme')} name="homme" />
                        <this.Switch
                            label={_('app.limitation')}
                            name="limitation"
                        />
                        <this.Switch
                            label={_('app.immigrant')}
                            name="immigrant"
                        />
                    </div>
                </div>
            </Drawer>
        )
    }
}

const Topbar = (props) => {
    const { ui, pages, toggleMenu, ...rest } = props

    const classes = useStyles()
    // const [open, setOpen] = useState(false)
    const [focused, setFocused] = useState(false)

    const updateMenu = (value) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        )
            return
        toggleMenu(value)
    }

    const focusSearch = (value) => {
        setFocused(value)
    }

    return (
        <div className={classes.wrapper}>
            <ElevationScroll {...rest}>
                <AppBar classes={{ root: classes.root }}>
                    <Menu
                        {...props}
                        classes={classes}
                        open={ui.menu}
                        updateMenu={updateMenu}
                    />

                    <Toolbar
                        variant="dense"
                        classes={{ root: classes.toolbar }}
                    >
                        <div
                            className={`${classes.mask} ${
                                focused ? 'active' : 'inactive'
                            }`}
                        />

                        <Link to={url()} href={url()}>
                            <Logo className={classes.logo} />
                        </Link>

                        <div className={classes.titleContainer}>
                            {props.title && (
                                <Typography className={classes.title}>
                                    {props.title}
                                </Typography>
                            )}
                        </div>

                        <SearchInput handleFocus={focusSearch} />

                        <Button
                            edge="end"
                            className={classes.menuButton}
                            onClick={updateMenu(true)}
                            color="inherit"
                            aria-label="Ouvrir le menu"
                        >
                            <div className={classes.menuButtonLabel}>Menu</div>
                            <MenuIcon />
                        </Button>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>

            {props.title && (
                <Typography className={classes['title-responsive']}>
                    {props.title}
                </Typography>
            )}
        </div>
    )
}

const mapStateToProps = (state, router) => {
    const { ui, filters, options, settings } = state
    const pages = getPages(state.pages)

    return {
        ui,
        filters,
        options,
        pages,
        title: getSetting(settings, 'app_title'),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateFilters: (payload) => dispatch(filterActions.update(payload)),
        toggleMenu: (payload) => dispatch(uiActions.toggleMenu(payload)),
    }
}

const connectedTopbar = connect(mapStateToProps, mapDispatchToProps)(Topbar)
export { connectedTopbar as Topbar }
