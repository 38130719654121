import React from 'react'
import { useStyles } from './Intro.styles'
import { Inner } from 'components/Inner'
import { Markdown } from 'components/Markdown/index'
import Diagram from 'components/Diagram/Diagram'

export const Intro = ({ title, content }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Inner paddingTop={false}>
                <div className={classes.flex}>
                    <div className={classes.col}>
                        <h2 className={classes.title}>{title}</h2>
                        <Markdown source={content} />
                    </div>
                    <div className={classes.col}>
                        <Diagram />
                    </div>
                </div>
            </Inner>
        </div>
    )
}
