import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(10),
        right: theme.spacing(2),
        width: theme.spacing(3),
        height: theme.spacing(3),
        padding: theme.spacing(2),
        backgroundColor: '#eee',
        boxSizing: 'content-box',
        zIndex: 100,

        '&:focus': {
            backgroundColor: '#eee',
        }
    },
}))