import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'

import { getLang } from 'helpers/lang'

export const getSetting = (settingState, key) => {
    if (isEmpty(settingState)) return null

    const result = values(settingState)
        .filter(p => p.lang === getLang())
        .find(setting => setting.key === key)

    if (!result) return null

    return result.value
}
