import { httpClient, save } from 'helpers'

export const pageService = {
    list,
}

const resource = 'pages'

function list(filters = undefined) {
    return httpClient.GET(resource + (filters ? filters : ''), {}, [save(resource)])
}
