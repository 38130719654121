import values from 'lodash/values'
import uniq from 'lodash/uniq'

export const searchByKey = key => (items, value) => items.filter(i => normalize(i[key]).includes(normalize(value.trim())))

export const convertPageToString = item => {
    const { id, slug, name, preview, matches, ...rest } = item
    return values(rest).join(' ')
}

export const regexSearch = (items, value) => {
    let words = filterTerms(value.split(' ').filter(v => v !== '')) // remove empty strings
    words = words.map(word => normalize(word))

    const reg = new RegExp("\\b(" + words.join('|') + ")", 'g') // create regex to check all words

    const results = items
        .map(item => {
            const string = normalize(convertPageToString(item))
            const matches = string.match(reg)
            return { ...item, matches: uniq(matches).length }
        })
        .filter(item => item.matches > 0)

    return results
}

// remove accents
export const normalize = str => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()

export const highlight = (source, terms) => {
    terms = [...new Set(filterTerms(terms))] // on garde que les termes uniques et on filtre les déterminants
    const maxchars = 250
    let result = '';

    let sentences = source.match(/\(?[^.?!]+[.!?]\)?/g) // séparer le texte en phrases
    if (!sentences) return null
    
    sentences = sentences.map(text => {
        text = text.replace(/(\r\n|\n|\r|\*|#)/gm, " ").trim() // retirer les * et sauts de ligne
        const words = []

        // trouver les occurences dans le texte
        terms.forEach(term => {
            const index = text.indexOf(term)
            if (index === -1) return

            const word = text.substring(index, index + term.length)

            words.push({
                start: index,
                end: index + term.length,
                word: word,
            })
        })


        // inverser les occurences
        words.sort((a, b) => {
            if (a.start < b.start) return 1
            if (a.start > b.start) return -1
            return 0
        }) 

        // wrapper les occurences en commencant par la fin du texte, pour que le début des mots restent au même index
        words.forEach(w => {
            const { start, end, word } = w        
            text = `${text.substring(0, start)}<strong>${word}</strong>${text.substring(end)}`
        })

        return { text, words }
    })

    sentences = sentences.filter(r => r.words.length > 0)
    
    // inverser les occurences
    sentences.sort((a, b) => {
        if (a.words.length < b.words.length) return 1
        if (a.words.length > b.words.length) return -1
        return 0
    }) 

    sentences.forEach(sentence => {
        if (result.length > maxchars) return false
        result = result + sentence.text + ' '
    })

    return result
}

export const filterTerms = terms => {
    const determinants = ['le', 'la', 'les', 'du', 'de', 'des', 'un', 'une', 'pour', 'et', 'en', 'à', 'pour']
    return terms.filter(term => !determinants.includes(term))
}
