import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { init, Integrations } from '@sentry/browser'

import config from 'config'
import { sentryMiddleware } from 'sentry'

import { uiState } from 'modules/ui/ui.reducer'
import { pageState } from 'modules/page/page.reducer'
import { alertState } from 'modules/alert/alert.reducer'
import { filterState } from 'modules/filter/filter.reducer'
import { settingState } from 'modules/setting/setting.reducer'
import { strategyState } from 'modules/strategy/strategy.reducer'
      
export const appReducer = combineReducers({
    alerts: alertState,
    strategies: strategyState,
    pages: pageState,
    filters: filterState,
    settings: settingState,
    ui: uiState,
})

const rootReducer = (state, action) => {
    return appReducer(state, action)
}

const loggerMiddleware = createLogger({ collapsed: true, diff: true })

const middlewares = [
    thunkMiddleware,
    ...(config.sentryDSN ? [sentryMiddleware] : []),
    ...(config.debugMode ? [loggerMiddleware] : []),
]

// Sentry Initialization
if (config.sentryDSN) {
    init({
        dsn: config.sentryDSN,
        environment: process.env.REACT_APP_ENV,
        integrations: [new Integrations.Breadcrumbs({ console: false })],
    })
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares))
