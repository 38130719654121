import React from 'react'

import { baseStyles } from './Title.styles'

export const Title = ({ children, lines = false, center = false }) => {
    const classes = baseStyles()
    const classNames = [
        classes.root,
        ...(lines ? [classes.lines] : []),
        ...(center ? [classes.center] : []),
    ].join(' ')

    children = lines
        ? <span className={classes.span}>{children}</span>
        : children

    return <h2 className={classNames}>
        {children}
    </h2>
}
