import { appUrls } from 'helpers'

export const removeEmpty = obj => {
    Object.keys(obj).forEach(key => obj[key] == null && delete obj[key])
    return obj
}

export const flattenChildren = data => {
    // copy data without children property
    let result = [...data]

    data.forEach(d => {
        if (d.children) {
            result = [...result, ...flattenChildren(d.children)]
        }
    })

    return result
}

export const getPageUrl = post => appUrls.page(post.slug)

export const getStrategyUrl = post => appUrls.strategy(post.slug)

export const formatPhone = string => string.replace(/-|\(|\)|\.|\s/g,'') // removes - . ( ) and spaces

export const getTitleFromSlug = string => {
    const a = string.split('-')
    if (!isNaN(a[0])) {
        a.shift()
    }
    a[0] = a[0][0].toUpperCase() + a[0].substring(1)
    return a.join(' ')
}


export const getNumberText = string => string.split('. ')[0] || false

export const truncate = (input, max) => {
    if (input.length > max) {
        return input.substring(0, max) + '...';
    }
    return input;
}

export const fixMediaUrls = url => {
    // if (process.env.REACT_APP_ENV !== 'start') {
    //     url = url.replace('/static/', '/frontend/static/')
    // }
    return url
}
