import React from 'react'
import Typography from '@material-ui/core/Typography'

import { useStyles } from './Pageheader.styles'
import { getColor } from 'utils/theme'
import { NumberTag } from 'components/NumberTag'

export const Pageheader = ({ title, number, numberText, children }) => {
    const classes = useStyles()
    const color = getColor(number)
    const colorClass = number >= 0 ? `${classes.root}--number ${classes.root}--${color}` : ''

    return (
        <header className={`${classes.root} ${colorClass}`}>
            {number >= 0 && numberText ? <NumberTag color={color} number={numberText} /> : null}
            <Typography variant="h1" align="center" className={classes.title}>{title}</Typography>
            {children}
        </header>
    )
}