import React from 'react'
import { connect } from 'react-redux'

import { getSetting } from 'modules/setting/setting.selectors'
import { Layout } from 'components/Layout'
import { Pageheader } from 'components/Pageheader'
import { BackButton, ButtonList } from 'components/Button'
import { Footer } from 'components/Footer'

import { _ } from 'helpers/lang'

class NotFoundComponent extends React.Component {
    render() {
        return (
            <Layout container>                      
                <Pageheader title="404" smalltitle={_('alert.404')} />
                <p>{_('alert.404Message')}</p>
                <ButtonList>
                    <BackButton/>
                </ButtonList>
                
                <Footer content={getSetting(this.props.settings, 'footer_text')} />
            </Layout>
        )
    }
}

function mapStateToProps(state) {
    const { settings } = state
    return {
        settings,
    }
}

export const NotFound = connect(mapStateToProps)(NotFoundComponent)
 