import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

export const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        padding: theme.spacing(2, 2, 2),
        margin: theme.spacing(0, 0, 4),
        background: fade(theme.palette.blue.light, 0.4),

        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, -3, 4),
        },

        '&--number': {
            padding: theme.spacing(4, 2, 2),
        },
        '&--blue': {
            background: fade(theme.palette.blue.light, 0.4),
        },
        '&--cyan': {
            background: fade(theme.palette.cyan.light, 0.4),
        },
        '&--yellow': {
            background: fade(theme.palette.yellow.light, 0.4),
        },
        '&--red': {
            background: fade(theme.palette.red.light, 0.4),
        },
        '&--green': {
            background: fade(theme.palette.green.light, 0.4),
        },
        '&--pink': {
            background: fade(theme.palette.pink.light, 0.4),
        },
    },

    title: {
        margin: theme.spacing(2, 0, 1),
    },
}))
