import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '100vh',
        width: '100%',
        marginTop: '80px',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            marginTop: '72px',
        },
        // background: props => {
        //     return (props.menu && !props.single) ? theme.palette[props.menu] : 'white'
        // },
    },
    '@global': {
        strong: {
            fontWeight: 'bold',
        },
    },
    content: {
        padding: theme.spacing(3, 0),
        position: 'relative',
    },
    contentbg: {
        [theme.breakpoints.up('md')]: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            position: 'absolute',
            height: '100%',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            opacity: '0.2',
            zIndex: '-1',
        },
    },
}))