import React from 'react'

import diagrammefr from 'assets/img/diagramme-fr.svg'
import diagrammeen from 'assets/img/diagramme-en.svg'
import { fixMediaUrls } from 'utils/functions'
import { getLang } from 'helpers/lang'
import { useStyles } from './Diagram.styles'

const diagram = {
    en: diagrammeen,
    fr: diagrammefr,
}

export default () => {
    const classes = useStyles()
    const lang = getLang();
    return <img className={classes.root} src={fixMediaUrls(diagram[lang])} alt="Diagramme outil"/>
}
