import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '1000px',
        margin: '80px auto 0',
        height: 0,
        boxSizing: 'content-box',
        padding: theme.spacing(0, 3),
        paddingBottom: '340px',
        
        [theme.breakpoints.down('md')]: {
            marginTop: '0',
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '34%',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0',
            paddingBottom: '100%',
        },
    },
    img: {
        display: 'block',
        width: '100%',
    },
}))
