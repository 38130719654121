import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable' // uses browserlist in package.json
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import { store, useHistory } from './helpers'
import { ErrorBoundary } from './sentry'
import { App } from './app'
import './service-worker'
import config from './config/index'

const ScrollTopWrapper = ({ children }) => {
    useHistory(h => h.listen((location, action) => {
        // on location change
        window.scrollTo(0, 0)
    }))

    return children
}

render(
    <ErrorBoundary>
        <Provider store={store}>
            <Router basename={config.basename}>
                <ScrollTopWrapper>
                    <App />
                </ScrollTopWrapper>
            </Router>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
)
