import React from 'react'

import { useStyles } from './Image.styles'
import { isOnline, _ } from 'helpers'

export const ItemImage = ({ item, ...props }) => {
    const classes = useStyles()

    if (!item.image) return null

    return (
        <div className={classes.root}>
            <Image
                classes={classes}
                alt={item.title}
                src={item.image.url}
                {...props}
            />
        </div>
    )
}

export const Image = props => {
    const baseClasses = useStyles()
    const classes = props.classes || baseClasses
    if (!isOnline()) {
        return <span className={classes.offline}>
            {_("app.image")}
        </span>
    }
    
    return (
        <img
            className={classes.img}
            alt={props.alt}
            src={props.src}
            {...props}
        />
    )
}
