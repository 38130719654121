
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    buttonRoot: {
        padding: theme.spacing(2, 2.5),
        margin: theme.spacing(0.5, 0),
        background: 'white',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: "#095797",
        flexGrow: 5,

        '&:hover': {
            borderColor: theme.palette.primary.main,
        },

        [theme.breakpoints.down('xs')]: {
            flexGrow: 1,
        },
    },
    buttonLabel: {
        display: 'inline-block',
        textAlign: 'left',
        textTransform: 'none',
    }
}))
