const env = require('./' + process.env.REACT_APP_ENV + '.json')

export default {
    basename: "/",
    domains: [
        'http://node.craip.lndo.site:3000',
        'http://craip.lndo.site',
        'https://craip.lndo.site',
        'https://craip.dev.arsenalweb.ca',
        'https://intervenonsenviolenceconjugale.santesaglac.gouv.qc.ca',
    ],
    apiUrl: "/api/v1/",
    pdfUrl: "/storage/pdf/",
    ...env,
}