import { createMuiTheme } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'

export const colors = ['blue', 'cyan', 'yellow', 'red', 'green', 'pink']

export const getColor = number => {
    number = number > 5 ? number - 6 : number // cycle thru colors
    return number >= 0 ? colors[number] : colors[0]
}

const blue = {
    main: '#095797',
    light: '#BCDAF3',
    dark: '#05345B',
    tab: '#05345B',
}

const cyan = {
    main: '#50C0D6',
    light: '#CDEEF4',
    tab: '#50C0D6',
}

const yellow = {
    main: '#FEDD76',
    light: '#FFEAA7',
    tab: 'rgb(236 202 95)',
}

const red = {
    main: '#FF7256',
    light: '#FFBCAE',
    tab: '#FF7256',
}

const green = {
    main: '#BED993',
    light: '#D9E8C2',
    tab: 'rgb(162 191 115)',
}

const pink = {
    main: '#DFB4BA',
    light: '#F5CFD4',
    tab: '#DFB4BA',
}


export const theme = createMuiTheme({
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    backgroundColor: 'white',
                    fontSize: '16px',
                },
            },
        },
        MuiTabs: {
            indicator: {
                backgroundColor: 'white',
            }
        },
        MuiTab: {
            root: {
                fontWeight: 'bold',
                padding: '20px 12px',
            },
            textColorInherit: {
                opacity: 1,
            }
        },
        MuiTabScrollButton: {
            root: {
                background: 'rgba(255, 255, 255, .3)',
            }
        }
    },

    // https://material-ui.com/customization/typography/
    typography: {
        fontFamily: [
            'Roboto',
            'Hind',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),

        h1: {
            fontSize: 26,
            fontWeight: 'bold',            
        },
        h2: {
            fontSize: 24,
            fontWeight: 'normal',            
        },
        h3: {
            fontSize: 22,
            fontWeight: 'bold',
            padding: '1em 0',
        },
        h4: {
            fontSize: 20,
            fontWeight: 'normal',            
        },
        h5: {
            fontSize: 16,
            fontWeight: 'black',            
        },
        h6: {
            fontSize: 14,
            fontWeight: 'normal',            
        },
    },
    palette: {
        primary: blue,
        secondary: grey,
        blue,
        cyan,
        yellow,
        red,
        green,
        pink,
    },
})
