import { store } from 'helpers'
import { pageActions } from 'modules/page/page.actions'
import { alertActions } from 'modules/alert/alert.actions'
import { settingActions } from 'modules/setting/setting.actions'
import { strategyActions } from 'modules/strategy/strategy.actions'

export const isDataExpired = () => {
    const now = Date.now()
    const updated_at = JSON.parse(localStorage.getItem('updated_at'))
    const expires_at = updated_at + 60 * 5
    return now >= expires_at
}

export const isOnline = () => {
    const offline = localStorage.getItem('offline') === 'true'
    return !offline
}

const shouldFetchData = () => {
    return isOnline() && isDataExpired()
}

export const fetchData = (force = false) => {
    if (shouldFetchData() || force) {
        store.dispatch(strategyActions.list())
        store.dispatch(pageActions.list())
        store.dispatch(settingActions.list())

        localStorage.setItem('updated_at', Date.now())

        if (force) {
            store.dispatch(alertActions.info('Données rafraîchies !'))
        }

        return true
    }

    return false
}
