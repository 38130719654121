import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import { Routes } from 'app/Routes'
import { Alert } from 'components/Alert'
import { fetchData } from 'helpers'
import { theme } from 'utils/theme'
import { Bottombar } from 'components/Bottombar/index'
import ScrollTopButton from 'components/ScrollTopButton/ScrollTopButton'

export class App extends React.Component {

    componentDidMount() {  
        fetchData()
    }


    render() {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Routes />
                <Bottombar />
                <Alert /> 
                <ScrollTopButton />
            </ThemeProvider>
        )
    }
}
