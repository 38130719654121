
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: '-1.5rem',
        right: '1.5rem',
        zIndex: 9,
        background: theme.palette.yellow.main,
        border: '2px solid #fff',
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,

        '&:hover': {
            background: theme.palette.yellow.light,
        }
    }
}))
