import { pageConstants } from 'modules/page/page.constants' // _constants

const storedData = JSON.parse(localStorage.getItem('pages'))
const initialState = storedData ? { loaded: false, data: [ ...storedData ]} : { loaded: false, data: []}

// all state changes manipulations happen here
export const pageState = function(state = initialState, { type, payload }) {
    switch (type) {
        
        case pageConstants.LIST_PENDING:
            return {
                ...state,
                loaded: false,
            }

        case pageConstants.LIST_SUCCESS:
            return {
                ...state,
                data: payload.data,
                loaded: true,
            }

        default:
            return state
    }
}
