import React from 'react'
export default props => ( 
    <svg
        width="249" height="128" viewBox="0 0 249 128" fill="none" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M102.955 103.664L102.953 90.5549H102.853C102.388 90.9849 100.664 91.0199 99.933 91.0199H98.667V91.1249C100.13 92.0249 99.931 93.3499 99.931 95.0019L99.933 100.551C99.933 103.328 97.208 105.272 95.011 105.272C92.117 105.272 90.917 103.219 90.917 99.9029V90.5549H90.816C90.35 90.9849 88.616 91.0199 87.885 91.0199H86.63V91.1249C88.092 92.0249 87.894 93.3499 87.894 95.0019V100.533C87.894 105.596 90.09 108 94.013 108C96.341 108 98.733 107.174 99.932 105.201V107.592H104.216V107.487C102.754 106.656 102.955 105.32 102.955 103.664Z" fill="black"/>
        <path d="M109.6 96.654C110 94.4 111.662 92.97 113.893 92.97C116.289 92.97 117.819 94.152 118.185 96.654H109.6ZM121.681 98.742C121.813 94.117 118.717 90.625 114.192 90.625C109.233 90.625 105.876 94.223 105.876 99.215C105.876 104.242 109.767 108.07 115.424 108.07C116.988 108.07 118.585 107.82 120.017 107.179L121.682 103.804H121.58C120.049 105.109 118.12 105.724 116.122 105.724C112.362 105.724 109.534 103.158 109.467 98.742H121.681Z" fill="black"/>
        <path d="M124.32 82.938C125.117 82.938 126.744 82.887 127.234 82.403H127.334V105.11C128.229 105.364 129.154 105.54 130.078 105.54C133.713 105.54 135.799 102.997 135.799 99.45C135.799 95.977 134.18 93.292 130.84 93.292C129.781 93.292 128.955 93.579 128.063 94.079L129.848 91.024C130.608 90.735 131.403 90.626 132.196 90.626C135.966 90.626 139.173 93.899 139.173 98.808C139.173 104.429 135.368 108.068 129.353 108.068C127.105 108.068 124.945 107.672 123.317 107.418V107.309C124.414 106.807 124.314 105.616 124.314 104.43V87.922C124.314 86.266 124.514 83.945 123.084 83.045V82.938H124.32Z" fill="black"/>
        <path d="M145.078 96.654C145.478 94.4 147.142 92.97 149.371 92.97C151.767 92.97 153.297 94.152 153.664 96.654H145.078ZM157.158 98.742C157.293 94.117 154.195 90.625 149.67 90.625C144.713 90.625 141.354 94.223 141.354 99.215C141.354 104.242 145.245 108.07 150.905 108.07C152.468 108.07 154.067 107.82 155.495 107.179L157.159 103.804H157.059C155.528 105.109 153.598 105.724 151.6 105.724C147.84 105.724 145.014 103.158 144.946 98.742H157.158Z" fill="black"/>
        <path d="M172.043 94.7379H171.943C170.978 93.5499 169.379 92.9699 167.882 92.9699C164.653 92.9699 162.327 95.4229 162.327 98.8449C162.327 102.986 165.552 105.724 169.245 105.724C170.942 105.724 172.806 105.003 174.106 103.956H174.204L172.474 107.358C171.076 107.928 169.546 108.069 168.079 108.069C162.958 108.069 158.95 104.462 158.95 99.7819C158.95 93.5849 163.421 90.6239 168.446 90.6239C169.645 90.6239 170.876 90.7689 172.042 91.0499V94.7379H172.043Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M117.527 87.654C117.527 85.978 117.527 84.047 117.527 84.047C115.347 85.029 113.322 86.858 111.968 88.635V90.039C115.206 87.855 117.527 87.756 117.527 87.654Z" fill="black"/>
        <path d="M72.937 105.596C67.912 105.596 64.416 99.9239 64.416 95.1079C64.416 95.0589 64.416 94.6159 64.417 94.5669C64.481 89.9009 67.501 85.5239 72.771 85.5239C78.317 85.5239 81.178 90.7739 81.223 95.7409C81.223 95.7839 81.223 96.2229 81.223 96.2619C81.223 101.559 77.928 105.596 72.937 105.596ZM90.16 111.104C86.451 111.713 82.885 110.541 79.094 106.208C82.754 104.368 85.018 99.7959 85.018 95.5419C85.018 95.4949 85.018 95.0559 85.018 95.0149C84.953 87.7379 79.487 82.9309 72.971 82.9309C66.442 82.9309 60.672 87.9309 60.623 95.2629C60.623 95.2979 60.621 95.7279 60.621 95.7549C60.621 102.892 65.914 108.187 72.404 108.187C73.336 108.187 74.234 108.046 75.133 107.83C79.383 111.809 83.172 113.508 86.446 113.113C88 112.927 89.639 112.308 90.624 111.029L90.16 111.104Z" fill="black"/>
        <path d="M27.8121 22.6381C27.2361 22.2781 26.5891 22.0981 25.8331 22.0981C23.8061 22.0981 22.6431 23.3211 22.6431 25.3121C22.6431 26.6071 23.5421 27.4471 25.0181 27.4471C25.6781 27.4471 26.3371 27.2671 27.0091 26.9311L26.7931 28.8501C26.0011 29.0541 25.2461 29.1621 24.5141 29.1621C21.8641 29.1621 20.3521 27.6751 20.3521 25.2521C20.3521 23.9681 20.8441 22.7331 21.6471 21.9061C22.6541 20.8741 23.8661 20.4551 25.5331 20.4551C26.4321 20.4551 27.3681 20.6221 28.3641 20.9471L27.8121 22.6381Z" fill="black"/>
        <path d="M30.035 26.4401C30.119 27.3521 30.622 27.7721 31.618 27.7721C32.278 27.7721 33.009 27.6161 33.717 27.3041L33.477 28.8271C32.746 29.0551 31.978 29.1751 31.198 29.1751C29.255 29.1751 28.104 28.0351 28.104 26.2841C28.104 25.2771 28.536 24.2331 29.208 23.5741C29.855 22.9381 30.791 22.6021 31.955 22.6021C33.67 22.6021 34.605 23.5741 34.605 25.2651C34.605 25.6131 34.557 26.0091 34.461 26.4401H30.035ZM32.841 24.9651C32.841 24.2811 32.422 23.8501 31.762 23.8501C31.102 23.8501 30.551 24.3661 30.311 25.2531H32.817C32.829 25.1571 32.841 25.0611 32.841 24.9651Z" fill="black"/>
        <path d="M41.72 28.971H39.573L40.22 25.877C40.268 25.649 40.292 25.445 40.292 25.265C40.292 24.678 39.992 24.353 39.512 24.353C38.66 24.353 38.097 25.025 37.809 26.368L37.258 28.971H35.1L36.419 22.746H38.35L38.11 23.85C38.806 23.058 39.597 22.651 40.461 22.651C41.732 22.651 42.476 23.43 42.476 24.81C42.476 25.206 42.404 25.769 42.248 26.513L41.72 28.971Z" fill="black"/>
        <path d="M47.122 22.806H48.645L48.333 24.281H46.81L46.354 26.428C46.294 26.692 46.27 26.872 46.27 26.968C46.27 27.412 46.462 27.604 46.941 27.604C47.109 27.604 47.349 27.568 47.649 27.484L47.337 28.959C46.845 29.043 46.449 29.091 46.149 29.091C44.866 29.091 44.134 28.407 44.134 27.22C44.134 27.004 44.158 26.8 44.194 26.608L44.686 24.281H43.427L43.739 22.806H44.998L45.31 21.343L47.565 20.743L47.122 22.806Z" fill="black"/>
        <path d="M53.889 24.569C53.505 24.461 53.193 24.401 52.953 24.401C51.97 24.401 51.537 25.012 51.262 26.308L50.686 28.971H48.527L49.786 22.783H51.741L51.513 23.91C52.113 23.046 52.712 22.639 53.552 22.639C53.816 22.639 54.08 22.687 54.367 22.771L53.889 24.569Z" fill="black"/>
        <path d="M56.17 26.4401C56.254 27.3521 56.758 27.7721 57.753 27.7721C58.413 27.7721 59.144 27.6161 59.852 27.3041L59.612 28.8271C58.881 29.0551 58.113 29.1751 57.333 29.1751C55.39 29.1751 54.239 28.0351 54.239 26.2841C54.239 25.2771 54.671 24.2331 55.343 23.5741C55.99 22.9381 56.926 22.6021 58.089 22.6021C59.804 22.6021 60.74 23.5741 60.74 25.2651C60.74 25.6131 60.692 26.0091 60.596 26.4401H56.17ZM58.977 24.9651C58.977 24.2811 58.557 23.8501 57.897 23.8501C57.237 23.8501 56.686 24.3661 56.446 25.2531H58.953C58.965 25.1571 58.977 25.0611 58.977 24.9651Z" fill="black"/>
        <path d="M68.6009 22.794L67.2579 28.971H65.1589L66.5019 22.794H68.6009ZM69.0449 20.647L68.8289 21.75H66.7299L66.9339 20.647H69.0449Z" fill="black"/>
        <path d="M75.523 28.971H73.377L74.024 25.877C74.072 25.649 74.096 25.445 74.096 25.265C74.096 24.678 73.796 24.353 73.317 24.353C72.465 24.353 71.901 25.025 71.614 26.368L71.062 28.971H68.903L70.222 22.746H72.153L71.913 23.85C72.609 23.058 73.4 22.651 74.264 22.651C75.535 22.651 76.279 23.43 76.279 24.81C76.279 25.206 76.207 25.769 76.051 26.513L75.523 28.971Z" fill="black"/>
        <path d="M80.9251 22.806H82.4481L82.1361 24.281H80.6131L80.1571 26.428C80.0971 26.692 80.0731 26.872 80.0731 26.968C80.0731 27.412 80.2651 27.604 80.7451 27.604C80.9131 27.604 81.1531 27.568 81.4531 27.484L81.1411 28.959C80.6491 29.043 80.2541 29.091 79.9541 29.091C78.6701 29.091 77.9391 28.407 77.9391 27.22C77.9391 27.004 77.9631 26.8 77.9991 26.608L78.4911 24.281H77.2321L77.5441 22.806H78.8031L79.1151 21.343L81.3701 20.743L80.9251 22.806Z" fill="black"/>
        <path d="M84.526 26.44C84.609 27.352 85.113 27.772 86.109 27.772C86.769 27.772 87.5 27.616 88.208 27.304L87.968 28.827C87.237 29.055 86.469 29.175 85.689 29.175C83.746 29.175 82.595 28.035 82.595 26.284C82.595 25.277 83.027 24.233 83.699 23.574C84.346 22.938 85.282 22.602 86.446 22.602C88.161 22.602 89.096 23.574 89.096 25.265C89.096 25.613 89.048 26.009 88.952 26.44H84.526ZM87.332 24.965C87.332 24.281 86.912 23.85 86.253 23.85C85.593 23.85 85.042 24.366 84.802 25.253H87.308C87.32 25.157 87.332 25.061 87.332 24.965ZM86.409 20.228H88.652V20.492L86.673 21.955H85.258V21.811L86.409 20.228Z" fill="black"/>
        <path d="M97.603 22.806L96.295 28.971C95.911 30.758 94.46 31.418 92.517 31.418C91.725 31.418 90.922 31.274 90.095 30.986L90.491 29.391C91.031 29.739 91.679 29.918 92.422 29.918C93.549 29.918 94.437 29.391 94.665 27.724C94.029 28.563 93.274 28.971 92.362 28.971C90.911 28.971 89.963 27.928 89.963 26.272C89.963 25.397 90.203 24.593 90.671 23.934C91.258 23.094 92.146 22.639 93.213 22.639C94.112 22.639 94.82 23.059 95.24 23.85L95.468 22.807H97.603V22.806ZM93.692 24.233C92.865 24.233 92.145 25.12 92.145 26.356C92.145 27.076 92.577 27.556 93.212 27.556C94.075 27.556 94.843 26.668 94.843 25.493C94.844 24.737 94.364 24.233 93.692 24.233Z" fill="black"/>
        <path d="M103.244 24.569C102.86 24.461 102.549 24.401 102.308 24.401C101.325 24.401 100.893 25.012 100.617 26.308L100.041 28.971H97.882L99.141 22.783H101.096L100.868 23.91C101.468 23.046 102.067 22.639 102.907 22.639C103.171 22.639 103.434 22.687 103.722 22.771L103.244 24.569Z" fill="black"/>
        <path d="M105.525 26.44C105.609 27.352 106.113 27.772 107.108 27.772C107.768 27.772 108.5 27.616 109.207 27.304L108.967 28.827C108.235 29.055 107.467 29.175 106.688 29.175C104.745 29.175 103.594 28.035 103.594 26.284C103.594 25.277 104.026 24.233 104.697 23.574C105.345 22.938 106.28 22.602 107.444 22.602C109.159 22.602 110.094 23.574 110.094 25.265C110.094 25.613 110.046 26.009 109.95 26.44H105.525ZM108.332 24.965C108.332 24.281 107.912 23.85 107.252 23.85C106.592 23.85 106.041 24.366 105.801 25.253H108.308C108.32 25.157 108.332 25.061 108.332 24.965ZM107.408 20.228H109.651V20.492L107.672 21.955H106.256V21.811L107.408 20.228Z" fill="black"/>
        <path d="M23.182 34.8061L22.535 37.9001C22.487 38.1161 22.463 38.3201 22.463 38.5121C22.463 39.1121 22.751 39.4351 23.279 39.4351C24.058 39.4351 24.658 38.7511 24.946 37.4081L25.498 34.8051H27.657L26.338 40.9701H24.407L24.647 39.9271C23.951 40.7191 23.16 41.1261 22.296 41.1261C21.025 41.1261 20.281 40.3581 20.281 38.9801C20.281 38.5721 20.353 37.9961 20.509 37.2651L21.025 34.8071H23.182V34.8061Z" fill="black"/>
        <path d="M34.496 40.971H32.35L32.997 37.877C33.045 37.649 33.069 37.445 33.069 37.265C33.069 36.678 32.769 36.353 32.29 36.353C31.438 36.353 30.874 37.025 30.587 38.368L30.035 40.971H27.876L29.195 34.746H31.126L30.886 35.85C31.582 35.058 32.373 34.651 33.237 34.651C34.508 34.651 35.252 35.43 35.252 36.81C35.252 37.206 35.18 37.769 35.024 38.513L34.496 40.971Z" fill="black"/>
        <path d="M39.598 34.794L38.255 40.971H36.156L37.499 34.794H39.598ZM40.042 32.647L39.826 33.75H37.727L37.931 32.647H40.042Z" fill="black"/>
        <path d="M42.574 34.806L43.053 39.244L45.248 34.806H47.503L43.881 40.971H41.47L40.439 34.806H42.574Z" fill="black"/>
        <path d="M49.438 38.44C49.522 39.352 50.025 39.772 51.021 39.772C51.681 39.772 52.412 39.6161 53.12 39.3041L52.88 40.827C52.149 41.055 51.381 41.175 50.601 41.175C48.658 41.175 47.507 40.035 47.507 38.284C47.507 37.2771 47.939 36.2331 48.611 35.5741C49.258 34.9381 50.194 34.6021 51.358 34.6021C53.073 34.6021 54.008 35.574 54.008 37.265C54.008 37.613 53.96 38.009 53.864 38.44H49.438ZM52.245 36.965C52.245 36.281 51.826 35.85 51.166 35.85C50.506 35.85 49.955 36.366 49.714 37.253H52.221C52.233 37.157 52.245 37.061 52.245 36.965Z" fill="black"/>
        <path d="M59.937 36.569C59.553 36.461 59.2409 36.401 59.0009 36.401C58.0179 36.401 57.586 37.012 57.31 38.308L56.734 40.971H54.575L55.834 34.783H57.789L57.561 35.91C58.161 35.046 58.7609 34.639 59.5999 34.639C59.8639 34.639 60.128 34.687 60.415 34.771L59.937 36.569Z" fill="black"/>
        <path d="M65.313 36.353C64.701 36.149 64.186 36.005 63.682 36.005C63.058 36.005 62.723 36.233 62.723 36.569C62.723 37.001 63.215 37.048 63.994 37.372C64.857 37.732 65.253 38.188 65.253 38.895C65.253 40.202 64.173 41.138 62.338 41.138C61.499 41.138 60.683 41.018 59.88 40.742L60.324 39.255C60.911 39.543 61.475 39.674 62.051 39.674C62.759 39.674 63.13 39.47 63.13 39.122C63.13 38.69 62.771 38.606 62.231 38.391C61.224 37.983 60.684 37.599 60.684 36.652C60.684 35.536 61.751 34.625 63.61 34.625C64.294 34.625 65.013 34.757 65.781 34.997L65.313 36.353Z" fill="black"/>
        <path d="M69.621 34.794L68.278 40.971H66.179L67.522 34.794H69.621ZM70.065 32.647L69.849 33.75H67.75L67.954 32.647H70.065Z" fill="black"/>
        <path d="M73.893 34.806H75.416L75.104 36.281H73.581L73.125 38.428C73.065 38.692 73.041 38.872 73.041 38.968C73.041 39.412 73.233 39.604 73.713 39.604C73.88 39.604 74.121 39.568 74.421 39.484L74.109 40.959C73.617 41.043 73.222 41.091 72.922 41.091C71.638 41.091 70.907 40.407 70.907 39.22C70.907 39.004 70.931 38.8 70.967 38.608L71.459 36.281H70.2L70.512 34.806H71.772L72.084 33.343L74.339 32.743L73.893 34.806Z" fill="black"/>
        <path d="M81.5 39.4121C81.404 39.8561 81.296 40.3711 81.249 40.9711H79.462L79.582 40.0351C79.103 40.7431 78.371 41.1021 77.447 41.1021C76.176 41.1021 75.384 40.3581 75.384 39.2671C75.384 37.8881 76.655 37.0241 79.018 37.0241C79.33 37.0241 79.665 37.0481 80.014 37.0841C80.026 37.0121 80.026 36.9401 80.026 36.8801C80.026 36.2561 79.618 35.9211 78.838 35.9211C78.119 35.9211 77.447 36.0771 76.823 36.3891L77.075 34.9501C77.746 34.7101 78.418 34.5901 79.126 34.5901C80.985 34.5901 81.992 35.3691 81.992 36.5571C81.992 36.8451 81.944 37.2281 81.86 37.6611L81.5 39.4121ZM79.149 38.1281C77.998 38.1281 77.398 38.4631 77.398 39.0871C77.398 39.4951 77.71 39.7821 78.19 39.7821C78.934 39.7821 79.605 39.1821 79.761 38.1511C79.545 38.1281 79.341 38.1281 79.149 38.1281Z" fill="black"/>
        <path d="M86.229 34.794L84.886 40.971H82.787L84.13 34.794H86.229ZM86.673 32.647L86.457 33.75H84.358L84.562 32.647H86.673Z" fill="black"/>
        <path d="M91.9641 36.569C91.5801 36.461 91.2691 36.401 91.0281 36.401C90.0451 36.401 89.6131 37.012 89.3371 38.308L88.7611 40.971H86.6021L87.8611 34.783H89.8161L89.5881 35.91C90.1881 35.046 90.7871 34.639 91.6271 34.639C91.8911 34.639 92.1551 34.687 92.4431 34.771L91.9641 36.569Z" fill="black"/>
        <path d="M94.2459 38.44C94.3299 39.352 94.8339 39.772 95.8289 39.772C96.4889 39.772 97.2209 39.6161 97.9279 39.3041L97.6879 40.827C96.9569 41.055 96.1889 41.175 95.4099 41.175C93.4669 41.175 92.3149 40.035 92.3149 38.284C92.3149 37.2771 92.7469 36.2331 93.4189 35.5741C94.0669 34.9381 95.0019 34.6021 96.1659 34.6021C97.8809 34.6021 98.8159 35.574 98.8159 37.265C98.8159 37.613 98.7679 38.009 98.6719 38.44H94.2459ZM97.0519 36.965C97.0519 36.281 96.6319 35.85 95.9719 35.85C95.3119 35.85 94.7609 36.366 94.5209 37.253H97.0279C97.0399 37.157 97.0519 37.061 97.0519 36.965Z" fill="black"/>
        <path d="M111.462 32.647L109.711 40.97H107.792L108.02 39.939C107.42 40.742 106.653 41.15 105.765 41.15C104.302 41.15 103.354 40.107 103.354 38.404C103.354 36.161 104.709 34.638 106.628 34.638C107.527 34.638 108.235 35.058 108.655 35.849L109.339 32.647H111.462ZM107.108 36.233C106.28 36.233 105.561 37.12 105.561 38.356C105.561 39.076 105.993 39.556 106.628 39.556C107.491 39.556 108.259 38.668 108.259 37.493C108.26 36.737 107.78 36.233 107.108 36.233Z" fill="black"/>
        <path d="M113.494 38.44C113.578 39.352 114.081 39.772 115.077 39.772C115.737 39.772 116.468 39.6161 117.176 39.3041L116.936 40.827C116.205 41.055 115.437 41.175 114.657 41.175C112.714 41.175 111.563 40.035 111.563 38.284C111.563 37.2771 111.995 36.2331 112.667 35.5741C113.314 34.9381 114.25 34.6021 115.414 34.6021C117.129 34.6021 118.064 35.574 118.064 37.265C118.064 37.613 118.016 38.009 117.92 38.44H113.494ZM116.3 36.965C116.3 36.281 115.881 35.85 115.221 35.85C114.561 35.85 114.01 36.366 113.77 37.253H116.276C116.288 37.157 116.3 37.061 116.3 36.965Z" fill="black"/>
        <path d="M127.615 36.353C127.004 36.149 126.488 36.005 125.984 36.005C125.361 36.005 125.025 36.233 125.025 36.569C125.025 37.001 125.516 37.048 126.296 37.372C127.159 37.732 127.555 38.188 127.555 38.895C127.555 40.202 126.476 41.138 124.64 41.138C123.801 41.138 122.985 41.018 122.182 40.742L122.626 39.255C123.213 39.543 123.777 39.674 124.353 39.674C125.061 39.674 125.432 39.47 125.432 39.122C125.432 38.69 125.073 38.606 124.533 38.391C123.526 37.983 122.986 37.599 122.986 36.652C122.986 35.536 124.053 34.625 125.913 34.625C126.597 34.625 127.315 34.757 128.083 34.997L127.615 36.353Z" fill="black"/>
        <path d="M134.527 39.4121C134.431 39.8561 134.323 40.3711 134.275 40.9711H132.488L132.608 40.0351C132.129 40.7431 131.396 41.1021 130.473 41.1021C129.202 41.1021 128.41 40.3581 128.41 39.2671C128.41 37.8881 129.681 37.0241 132.045 37.0241C132.357 37.0241 132.692 37.0481 133.04 37.0841C133.052 37.0121 133.052 36.9401 133.052 36.8801C133.052 36.2561 132.645 35.9211 131.864 35.9211C131.145 35.9211 130.473 36.0771 129.849 36.3891L130.101 34.9501C130.773 34.7101 131.445 34.5901 132.152 34.5901C134.011 34.5901 135.018 35.3691 135.018 36.5571C135.018 36.8451 134.97 37.2281 134.886 37.6611L134.527 39.4121ZM132.177 38.1281C131.026 38.1281 130.426 38.4631 130.426 39.0871C130.426 39.4951 130.738 39.7821 131.217 39.7821C131.961 39.7821 132.632 39.1821 132.788 38.1511C132.572 38.1281 132.368 38.1281 132.177 38.1281Z" fill="black"/>
        <path d="M142.207 40.971H140.061L140.708 37.877C140.756 37.649 140.779 37.445 140.779 37.265C140.779 36.678 140.479 36.353 140 36.353C139.148 36.353 138.585 37.025 138.297 38.368L137.745 40.971H135.587L136.906 34.746H138.837L138.597 35.85C139.293 35.058 140.084 34.651 140.948 34.651C142.219 34.651 142.964 35.43 142.964 36.81C142.964 37.206 142.892 37.769 142.735 38.513L142.207 40.971Z" fill="black"/>
        <path d="M147.608 34.806H149.131L148.818 36.281H147.296L146.84 38.428C146.779 38.692 146.756 38.872 146.756 38.968C146.756 39.412 146.947 39.604 147.428 39.604C147.596 39.604 147.835 39.568 148.135 39.484L147.823 40.959C147.331 41.043 146.935 41.091 146.635 41.091C145.352 41.091 144.62 40.407 144.62 39.22C144.62 39.004 144.643 38.8 144.68 38.608L145.172 36.281H143.912L144.225 34.806H145.484L145.796 33.343L148.051 32.743L147.608 34.806Z" fill="black"/>
        <path d="M151.209 38.44C151.293 39.352 151.797 39.772 152.792 39.772C153.452 39.772 154.184 39.616 154.891 39.304L154.652 40.827C153.921 41.055 153.153 41.175 152.373 41.175C150.431 41.175 149.279 40.035 149.279 38.284C149.279 37.277 149.711 36.233 150.383 35.574C151.03 34.938 151.966 34.602 153.129 34.602C154.844 34.602 155.779 35.574 155.779 37.265C155.779 37.613 155.731 38.009 155.635 38.44H151.209ZM154.016 36.965C154.016 36.281 153.596 35.85 152.937 35.85C152.277 35.85 151.725 36.366 151.485 37.253H153.992C154.004 37.157 154.016 37.061 154.016 36.965ZM153.092 32.228H155.335V32.492L153.356 33.955H151.941V33.811L153.092 32.228Z" fill="black"/>
        <path d="M22.091 50.44C22.175 51.352 22.678 51.772 23.674 51.772C24.334 51.772 25.065 51.6161 25.773 51.3041L25.533 52.827C24.802 53.055 24.034 53.175 23.254 53.175C21.311 53.175 20.16 52.035 20.16 50.284C20.16 49.2771 20.592 48.2331 21.264 47.5741C21.911 46.9381 22.847 46.6021 24.011 46.6021C25.726 46.6021 26.661 47.574 26.661 49.265C26.661 49.613 26.613 50.009 26.517 50.44H22.091ZM24.897 48.965C24.897 48.281 24.478 47.85 23.818 47.85C23.158 47.85 22.607 48.366 22.367 49.253H24.873C24.885 49.157 24.897 49.061 24.897 48.965Z" fill="black"/>
        <path d="M31.125 46.806H32.648L32.336 48.281H30.813L30.357 50.428C30.297 50.692 30.274 50.872 30.274 50.968C30.274 51.412 30.465 51.604 30.945 51.604C31.113 51.604 31.353 51.568 31.653 51.484L31.341 52.959C30.849 53.043 30.453 53.091 30.153 53.091C28.87 53.091 28.138 52.407 28.138 51.22C28.138 51.004 28.162 50.8 28.198 50.608L28.69 48.281H27.431L27.743 46.806H29.002L29.314 45.343L31.569 44.743L31.125 46.806Z" fill="black"/>
        <path d="M44.6111 44.647L42.8601 52.97H40.9411L41.1691 51.939C40.5691 52.742 39.8021 53.15 38.9141 53.15C37.4511 53.15 36.5031 52.107 36.5031 50.404C36.5031 48.161 37.8581 46.638 39.7771 46.638C40.6761 46.638 41.3841 47.058 41.8041 47.849L42.4881 44.647H44.6111ZM40.2571 48.233C39.4291 48.233 38.7101 49.12 38.7101 50.356C38.7101 51.076 39.1421 51.556 39.7771 51.556C40.6401 51.556 41.4081 50.668 41.4081 49.493C41.4091 48.737 40.9291 48.233 40.2571 48.233Z" fill="black"/>
        <path d="M46.643 50.44C46.727 51.352 47.23 51.772 48.226 51.772C48.886 51.772 49.617 51.6161 50.325 51.3041L50.085 52.827C49.354 53.055 48.586 53.175 47.806 53.175C45.863 53.175 44.712 52.035 44.712 50.284C44.712 49.2771 45.144 48.2331 45.816 47.5741C46.463 46.9381 47.399 46.6021 48.563 46.6021C50.278 46.6021 51.213 47.574 51.213 49.265C51.213 49.613 51.165 50.009 51.069 50.44H46.643ZM49.449 48.965C49.449 48.281 49.03 47.85 48.37 47.85C47.71 47.85 47.159 48.366 46.919 49.253H49.425C49.437 49.157 49.449 49.061 49.449 48.965Z" fill="black"/>
        <path d="M60.765 48.353C60.153 48.149 59.638 48.005 59.134 48.005C58.51 48.005 58.175 48.233 58.175 48.569C58.175 49.001 58.667 49.048 59.446 49.372C60.31 49.732 60.705 50.188 60.705 50.895C60.705 52.202 59.625 53.138 57.79 53.138C56.951 53.138 56.135 53.018 55.332 52.742L55.776 51.255C56.364 51.543 56.927 51.674 57.503 51.674C58.211 51.674 58.583 51.47 58.583 51.122C58.583 50.69 58.223 50.606 57.683 50.391C56.676 49.983 56.136 49.599 56.136 48.652C56.136 47.536 57.203 46.625 59.062 46.625C59.746 46.625 60.465 46.757 61.233 46.997L60.765 48.353Z" fill="black"/>
        <path d="M63.67 50.44C63.754 51.352 64.257 51.772 65.253 51.772C65.913 51.772 66.644 51.6161 67.352 51.3041L67.112 52.827C66.381 53.055 65.613 53.175 64.833 53.175C62.89 53.175 61.739 52.035 61.739 50.284C61.739 49.2771 62.171 48.2331 62.843 47.5741C63.49 46.9381 64.426 46.6021 65.59 46.6021C67.305 46.6021 68.24 47.574 68.24 49.265C68.24 49.613 68.192 50.009 68.096 50.44H63.67ZM66.477 48.965C66.477 48.281 66.058 47.85 65.398 47.85C64.738 47.85 64.187 48.366 63.947 49.253H66.453C66.465 49.157 66.477 49.061 66.477 48.965Z" fill="black"/>
        <path d="M74.168 48.569C73.784 48.461 73.472 48.401 73.232 48.401C72.249 48.401 71.817 49.012 71.541 50.308L70.965 52.971H68.806L70.065 46.783H72.02L71.792 47.91C72.392 47.046 72.991 46.639 73.831 46.639C74.095 46.639 74.359 46.687 74.647 46.771L74.168 48.569Z" fill="black"/>
        <path d="M76.858 46.806L77.338 51.244L79.532 46.806H81.787L78.165 52.971H75.754L74.723 46.806H76.858Z" fill="black"/>
        <path d="M85.125 46.794L83.782 52.971H81.683L83.026 46.794H85.125ZM85.569 44.647L85.353 45.75H83.254L83.458 44.647H85.569Z" fill="black"/>
        <path d="M91.16 48.5211C90.704 48.3051 90.261 48.1971 89.841 48.1971C88.69 48.1971 87.934 48.9171 87.934 49.9721C87.934 50.8961 88.546 51.4831 89.529 51.4831C89.925 51.4831 90.321 51.3871 90.753 51.1951L90.5 52.8271C89.852 52.9951 89.276 53.0791 88.737 53.0791C86.842 53.0791 85.679 51.9151 85.679 50.2131C85.679 48.1261 87.178 46.6271 89.589 46.6271C90.285 46.6271 90.968 46.7591 91.652 47.0351L91.16 48.5211Z" fill="black"/>
        <path d="M93.67 50.44C93.754 51.352 94.257 51.772 95.253 51.772C95.913 51.772 96.644 51.6161 97.352 51.3041L97.112 52.827C96.381 53.055 95.613 53.175 94.833 53.175C92.89 53.175 91.739 52.035 91.739 50.284C91.739 49.2771 92.171 48.2331 92.843 47.5741C93.49 46.9381 94.426 46.6021 95.59 46.6021C97.305 46.6021 98.24 47.574 98.24 49.265C98.24 49.613 98.192 50.009 98.096 50.44H93.67ZM96.476 48.965C96.476 48.281 96.057 47.85 95.397 47.85C94.737 47.85 94.186 48.366 93.946 49.253H96.452C96.464 49.157 96.476 49.061 96.476 48.965Z" fill="black"/>
        <path d="M104.096 48.353C103.484 48.149 102.969 48.005 102.465 48.005C101.841 48.005 101.506 48.233 101.506 48.569C101.506 49.001 101.998 49.048 102.777 49.372C103.64 49.732 104.036 50.188 104.036 50.895C104.036 52.202 102.956 53.138 101.121 53.138C100.282 53.138 99.466 53.018 98.663 52.742L99.107 51.255C99.694 51.543 100.258 51.674 100.834 51.674C101.542 51.674 101.913 51.47 101.913 51.122C101.913 50.69 101.553 50.606 101.014 50.391C100.007 49.983 99.467 49.599 99.467 48.652C99.467 47.536 100.535 46.625 102.394 46.625C103.078 46.625 103.797 46.757 104.564 46.997L104.096 48.353Z" fill="black"/>
        <path d="M113.792 48.353C113.18 48.149 112.665 48.005 112.161 48.005C111.537 48.005 111.202 48.233 111.202 48.569C111.202 49.001 111.694 49.048 112.473 49.372C113.337 49.732 113.732 50.188 113.732 50.895C113.732 52.202 112.652 53.138 110.817 53.138C109.978 53.138 109.162 53.018 108.359 52.742L108.803 51.255C109.391 51.543 109.954 51.674 110.53 51.674C111.238 51.674 111.61 51.47 111.61 51.122C111.61 50.69 111.25 50.606 110.71 50.391C109.703 49.983 109.163 49.599 109.163 48.652C109.163 47.536 110.23 46.625 112.089 46.625C112.773 46.625 113.492 46.757 114.26 46.997L113.792 48.353Z" fill="black"/>
        <path d="M121.927 49.541C121.927 51.652 120.392 53.151 117.933 53.151C115.954 53.151 114.766 52.06 114.766 50.333C114.766 48.126 116.253 46.639 118.724 46.639C120.728 46.638 121.927 47.742 121.927 49.541ZM118.604 48.053C117.741 48.053 116.949 48.989 116.949 50.308C116.949 51.123 117.441 51.699 118.136 51.699C119 51.699 119.755 50.763 119.755 49.445C119.756 48.581 119.288 48.053 118.604 48.053Z" fill="black"/>
        <path d="M128.168 48.521C127.712 48.305 127.269 48.197 126.849 48.197C125.698 48.197 124.942 48.917 124.942 49.972C124.942 50.896 125.553 51.483 126.537 51.483C126.933 51.483 127.329 51.387 127.761 51.195L127.509 52.826C126.862 52.994 126.286 53.078 125.746 53.078C123.851 53.078 122.687 51.914 122.687 50.212C122.687 48.125 124.187 46.626 126.597 46.626C127.292 46.626 127.976 46.758 128.66 47.034L128.168 48.521Z" fill="black"/>
        <path d="M132.081 46.794L130.737 52.971H128.638L129.981 46.794H132.081ZM132.524 44.647L132.308 45.75H130.209L130.412 44.647H132.524Z" fill="black"/>
        <path d="M138.655 51.4121C138.559 51.8561 138.451 52.3711 138.403 52.9711H136.616L136.736 52.0351C136.257 52.7431 135.524 53.1021 134.601 53.1021C133.33 53.1021 132.538 52.3581 132.538 51.2671C132.538 49.8881 133.809 49.0241 136.173 49.0241C136.485 49.0241 136.82 49.0481 137.168 49.0841C137.18 49.0121 137.18 48.9401 137.18 48.8801C137.18 48.2561 136.773 47.9211 135.992 47.9211C135.273 47.9211 134.601 48.0771 133.977 48.3891L134.229 46.9501C134.901 46.7101 135.573 46.5901 136.28 46.5901C138.139 46.5901 139.146 47.3691 139.146 48.5571C139.146 48.8451 139.098 49.2281 139.014 49.6611L138.655 51.4121ZM136.305 50.1281C135.154 50.1281 134.554 50.4631 134.554 51.0871C134.554 51.4951 134.866 51.7821 135.345 51.7821C136.089 51.7821 136.76 51.1821 136.916 50.1511C136.7 50.1281 136.496 50.1281 136.305 50.1281Z" fill="black"/>
        <path d="M143.072 46.8061L142.425 49.9001C142.377 50.1161 142.354 50.3201 142.354 50.5121C142.354 51.1121 142.641 51.4351 143.169 51.4351C143.948 51.4351 144.548 50.7511 144.836 49.4081L145.388 46.8051H147.546L146.227 52.9701H144.296L144.536 51.9271C143.84 52.7191 143.049 53.1261 142.185 53.1261C140.914 53.1261 140.169 52.3581 140.169 50.9801C140.169 50.5721 140.241 49.9961 140.398 49.2651L140.914 46.8071H143.072V46.8061Z" fill="black"/>
        <path d="M150.873 46.806L151.532 48.617L152.899 46.806H155.358L152.767 49.768L154.17 52.97H151.892L151.16 51.027L149.745 52.97H147.179L149.902 49.684L148.583 46.806H150.873Z" fill="black"/>
        <path d="M28.279 56.647L26.528 64.97H24.609L24.837 63.939C24.237 64.743 23.469 65.15 22.582 65.15C21.119 65.15 20.171 64.107 20.171 62.404C20.171 60.161 21.526 58.638 23.445 58.638C24.345 58.638 25.052 59.058 25.472 59.849L26.156 56.647H28.279ZM23.926 60.233C23.098 60.233 22.379 61.12 22.379 62.356C22.379 63.076 22.811 63.556 23.446 63.556C24.31 63.556 25.077 62.668 25.077 61.493C25.077 60.737 24.597 60.233 23.926 60.233Z" fill="black"/>
        <path d="M31.402 58.8061L30.754 61.9001C30.706 62.1161 30.682 62.3201 30.682 62.5121C30.682 63.1121 30.97 63.4351 31.497 63.4351C32.277 63.4351 32.876 62.7511 33.164 61.4081L33.716 58.8051H35.875L34.556 64.9701H32.625L32.865 63.9271C32.17 64.7191 31.378 65.1271 30.514 65.1271C29.243 65.1271 28.499 64.3591 28.499 62.9801C28.499 62.5721 28.571 61.9961 28.727 61.2651L29.243 58.8071H31.402V58.8061Z" fill="black"/>
        <path d="M46.5681 58.458C45.9571 58.182 45.3331 58.05 44.6851 58.05C43.6651 58.05 43.1501 58.362 43.1501 58.902C43.1501 59.49 43.7261 59.55 44.6011 59.969C45.8121 60.557 46.3881 61.061 46.3881 62.176C46.3881 63.927 44.9851 65.163 42.5141 65.163C41.5311 65.163 40.6071 65.018 39.7321 64.707L40.2111 62.992C40.8831 63.328 41.5301 63.508 42.1781 63.508C43.3531 63.508 44.0131 63.089 44.0131 62.465C44.0131 61.746 43.3411 61.613 42.2021 61.134C41.2661 60.738 40.7991 60.103 40.7991 59.179C40.7991 57.62 42.1541 56.469 44.3011 56.469C45.4891 56.469 46.4361 56.601 47.0841 56.841L46.5681 58.458Z" fill="black"/>
        <path d="M53.372 63.4121C53.276 63.8561 53.168 64.3711 53.121 64.9711H51.334L51.454 64.0351C50.975 64.7431 50.243 65.1021 49.319 65.1021C48.048 65.1021 47.256 64.3591 47.256 63.2671C47.256 61.8881 48.527 61.0241 50.89 61.0241C51.202 61.0241 51.537 61.0481 51.886 61.0841C51.898 61.0121 51.898 60.9401 51.898 60.8801C51.898 60.2561 51.49 59.9211 50.711 59.9211C49.991 59.9211 49.319 60.0771 48.696 60.3891L48.948 58.9501C49.619 58.7101 50.291 58.5901 50.999 58.5901C52.858 58.5901 53.866 59.3691 53.866 60.5571C53.866 60.8451 53.818 61.2281 53.734 61.6611L53.372 63.4121ZM51.021 62.1281C49.87 62.1281 49.27 62.4631 49.27 63.0871C49.27 63.4951 49.582 63.7821 50.062 63.7821C50.806 63.7821 51.478 63.1821 51.633 62.1511C51.417 62.1281 51.213 62.1281 51.021 62.1281Z" fill="black"/>
        <path d="M62.443 58.8061L61.135 64.9711C60.751 66.7581 59.3 67.4171 57.357 67.4171C56.565 67.4171 55.762 67.2731 54.935 66.9851L55.331 65.3901C55.871 65.7381 56.519 65.9171 57.262 65.9171C58.389 65.9171 59.277 65.3901 59.505 63.7231C58.869 64.5621 58.114 64.9701 57.202 64.9701C55.751 64.9701 54.803 63.9271 54.803 62.2711C54.803 61.3961 55.043 60.5921 55.511 59.9331C56.098 59.0931 56.986 58.6381 58.053 58.6381C58.952 58.6381 59.66 59.0581 60.08 59.8491L60.308 58.8061H62.443ZM58.533 60.2331C57.706 60.2331 56.986 61.1201 56.986 62.3561C56.986 63.0761 57.418 63.5561 58.053 63.5561C58.916 63.5561 59.684 62.6681 59.684 61.4931C59.685 60.7371 59.205 60.2331 58.533 60.2331Z" fill="black"/>
        <path d="M66.0101 58.8061L65.3621 61.9001C65.3141 62.1161 65.2901 62.3201 65.2901 62.5121C65.2901 63.1121 65.5781 63.4351 66.1051 63.4351C66.8851 63.4351 67.4841 62.7511 67.7721 61.4081L68.3241 58.8051H70.4831L69.1641 64.9701H67.2331L67.4731 63.9271C66.7771 64.7191 65.9861 65.1271 65.1221 65.1271C63.8511 65.1271 63.1071 64.3591 63.1071 62.9801C63.1071 62.5721 63.1791 61.9961 63.3351 61.2651L63.8511 58.8071H66.0101V58.8061Z" fill="black"/>
        <path d="M72.9701 62.44C73.0541 63.352 73.5581 63.772 74.5531 63.772C75.2131 63.772 75.9451 63.6161 76.6521 63.3041L76.4121 64.8271C75.6811 65.0551 74.9131 65.1751 74.1341 65.1751C72.1911 65.1751 71.0391 64.035 71.0391 62.284C71.0391 61.2771 71.4711 60.2331 72.1431 59.5741C72.7911 58.9381 73.7261 58.6021 74.8901 58.6021C76.6051 58.6021 77.5401 59.574 77.5401 61.265C77.5401 61.613 77.4921 62.009 77.3961 62.44H72.9701ZM75.7771 60.965C75.7771 60.281 75.3571 59.85 74.6971 59.85C74.0371 59.85 73.4861 60.366 73.2461 61.253H75.7531C75.7651 61.157 75.7771 61.061 75.7771 60.965Z" fill="black"/>
        <path d="M84.656 64.971H82.509L83.157 61.877C83.205 61.649 83.229 61.445 83.229 61.265C83.229 60.678 82.929 60.353 82.45 60.353C81.598 60.353 81.034 61.025 80.747 62.368L80.195 64.971H78.036L79.355 58.746H81.286L81.046 59.85C81.741 59.058 82.533 58.651 83.397 58.651C84.668 58.651 85.412 59.43 85.412 60.81C85.412 61.206 85.34 61.769 85.184 62.513L84.656 64.971Z" fill="black"/>
        <path d="M92.36 63.4121C92.264 63.8561 92.156 64.3711 92.108 64.9711H90.321L90.441 64.0351C89.961 64.7431 89.23 65.1021 88.306 65.1021C87.035 65.1021 86.243 64.3591 86.243 63.2671C86.243 61.8881 87.514 61.0241 89.877 61.0241C90.189 61.0241 90.525 61.0481 90.873 61.0841C90.885 61.0121 90.885 60.9401 90.885 60.8801C90.885 60.2561 90.477 59.9211 89.697 59.9211C88.977 59.9211 88.306 60.0771 87.682 60.3891L87.933 58.9501C88.605 58.7101 89.276 58.5901 89.984 58.5901C91.843 58.5901 92.85 59.3691 92.85 60.5571C92.85 60.8451 92.802 61.2281 92.718 61.6611L92.36 63.4121ZM90.009 62.1281C88.858 62.1281 88.258 62.4631 88.258 63.0871C88.258 63.4951 88.57 63.7821 89.05 63.7821C89.794 63.7821 90.465 63.1821 90.621 62.1511C90.405 62.1281 90.201 62.1281 90.009 62.1281Z" fill="black"/>
        <path d="M96.082 58.8061L96.454 61.2881C96.514 61.6961 96.598 62.2601 96.646 62.9551C96.898 62.3551 97.102 61.9231 97.246 61.6471L98.781 58.8051H101.024L97.066 65.3781C96.262 66.7211 95.59 67.3811 94.175 67.3811C93.695 67.3811 93.155 67.2731 92.532 67.0681L93.035 65.5691C93.359 65.7131 93.659 65.7851 93.935 65.7851C94.595 65.7851 95.05 65.6651 95.314 65.0171L93.935 58.8051H96.082V58.8061Z" fill="black"/>
        <path d="M106.784 60.905L106.532 62.069H100.547L100.787 60.905H106.784Z" fill="black"/>
        <path d="M24.07 68.647L22.643 75.315H26.085L25.725 76.97H20.052L21.827 68.647H24.07Z" fill="black"/>
        <path d="M32.876 75.4111C32.78 75.8551 32.672 76.3711 32.624 76.9711H30.837L30.957 76.0351C30.477 76.7431 29.746 77.1021 28.822 77.1021C27.551 77.1021 26.759 76.3591 26.759 75.2671C26.759 73.8881 28.03 73.0241 30.393 73.0241C30.705 73.0241 31.041 73.0481 31.389 73.0851C31.401 73.0131 31.401 72.9401 31.401 72.8811C31.401 72.2571 30.993 71.9221 30.213 71.9221C29.493 71.9221 28.822 72.0771 28.198 72.3901L28.45 70.9511C29.122 70.7111 29.793 70.5911 30.501 70.5911C32.36 70.5911 33.368 71.3711 33.368 72.5591C33.368 72.8461 33.32 73.2301 33.236 73.6621L32.876 75.4111ZM30.526 74.1281C29.375 74.1281 28.775 74.4641 28.775 75.0881C28.775 75.4951 29.087 75.7831 29.567 75.7831C30.31 75.7831 30.982 75.1831 31.138 74.1521C30.921 74.1281 30.718 74.1281 30.526 74.1281Z" fill="black"/>
        <path d="M39.668 72.521C39.212 72.305 38.769 72.197 38.349 72.197C37.198 72.197 36.442 72.917 36.442 73.972C36.442 74.895 37.054 75.483 38.037 75.483C38.433 75.483 38.829 75.387 39.26 75.195L39.008 76.827C38.361 76.995 37.785 77.079 37.245 77.079C35.35 77.079 34.186 75.915 34.186 74.212C34.186 72.125 35.685 70.626 38.096 70.626C38.791 70.626 39.475 70.758 40.159 71.034L39.668 72.521Z" fill="black"/>
        <path d="M43.6419 72.725L43.3179 74.237H40.0909L40.4149 72.725H43.6419Z" fill="black"/>
        <path d="M50.06 70.458C49.449 70.183 48.825 70.051 48.177 70.051C47.157 70.051 46.642 70.363 46.642 70.903C46.642 71.49 47.218 71.55 48.093 71.97C49.304 72.558 49.88 73.061 49.88 74.176C49.88 75.928 48.477 77.163 46.006 77.163C45.023 77.163 44.099 77.018 43.224 76.707L43.703 74.992C44.375 75.327 45.022 75.508 45.67 75.508C46.845 75.508 47.505 75.088 47.505 74.464C47.505 73.744 46.833 73.612 45.694 73.133C44.758 72.737 44.291 72.102 44.291 71.178C44.291 69.619 45.646 68.468 47.793 68.468C48.981 68.468 49.928 68.6 50.576 68.839L50.06 70.458Z" fill="black"/>
        <path d="M56.864 75.4111C56.768 75.8551 56.66 76.3711 56.613 76.9711H54.826L54.946 76.0351C54.467 76.7431 53.735 77.1021 52.811 77.1021C51.54 77.1021 50.748 76.3591 50.748 75.2671C50.748 73.8881 52.019 73.0241 54.382 73.0241C54.694 73.0241 55.029 73.0481 55.378 73.0851C55.39 73.0131 55.39 72.9401 55.39 72.8811C55.39 72.2571 54.982 71.9221 54.203 71.9221C53.483 71.9221 52.811 72.0771 52.188 72.3901L52.44 70.9511C53.111 70.7111 53.783 70.5911 54.491 70.5911C56.35 70.5911 57.357 71.3711 57.357 72.5591C57.357 72.8461 57.309 73.2301 57.225 73.6621L56.864 75.4111ZM54.513 74.1281C53.362 74.1281 52.762 74.4641 52.762 75.0881C52.762 75.4951 53.074 75.7831 53.554 75.7831C54.298 75.7831 54.97 75.1831 55.125 74.1521C54.909 74.1281 54.705 74.1281 54.513 74.1281Z" fill="black"/>
        <path d="M61.593 70.794L60.25 76.971H58.151L59.494 70.794H61.593ZM62.037 68.647L61.821 69.751H59.722L59.926 68.647H62.037Z" fill="black"/>
        <path d="M68.516 76.971H66.369L67.017 73.876C67.065 73.648 67.089 73.444 67.089 73.265C67.089 72.677 66.789 72.354 66.309 72.354C65.458 72.354 64.894 73.025 64.606 74.369L64.054 76.972H61.895L63.214 70.747H65.145L64.905 71.851C65.6 71.059 66.392 70.652 67.256 70.652C68.527 70.652 69.271 71.431 69.271 72.81C69.271 73.206 69.199 73.77 69.043 74.513L68.516 76.971Z" fill="black"/>
        <path d="M73.917 70.806H75.44L75.128 72.282H73.605L73.149 74.428C73.089 74.693 73.065 74.872 73.065 74.968C73.065 75.411 73.257 75.604 73.736 75.604C73.904 75.604 74.144 75.568 74.444 75.484L74.132 76.96C73.64 77.044 73.244 77.092 72.944 77.092C71.661 77.092 70.929 76.408 70.929 75.221C70.929 75.005 70.953 74.801 70.989 74.61L71.481 72.283H70.222L70.534 70.807H71.793L72.105 69.344L74.36 68.744L73.917 70.806Z" fill="black"/>
        <path d="M78.9811 72.725L78.6571 74.237H75.4301L75.7541 72.725H78.9811Z" fill="black"/>
        <path d="M84.644 68.647L83.373 74.559C82.989 76.346 81.97 77.163 80.135 77.163C79.535 77.163 79.008 77.079 78.528 76.85L78.996 75.039C79.355 75.159 79.667 75.23 79.932 75.23C80.663 75.23 81.119 74.787 81.311 73.899L82.415 68.646H84.644V68.647Z" fill="black"/>
        <path d="M86.649 74.4401C86.733 75.3511 87.237 75.7711 88.232 75.7711C88.892 75.7711 89.624 75.6151 90.331 75.3031L90.091 76.8261C89.359 77.0541 88.591 77.1741 87.812 77.1741C85.869 77.1741 84.718 76.0341 84.718 74.2831C84.718 73.2751 85.15 72.2321 85.821 71.5721C86.469 70.9371 87.404 70.6011 88.568 70.6011C90.283 70.6011 91.218 71.5721 91.218 73.2631C91.218 73.6111 91.17 74.0071 91.074 74.4391H86.649V74.4401ZM89.456 72.9651C89.456 72.2811 89.036 71.8501 88.376 71.8501C87.716 71.8501 87.165 72.3661 86.925 73.2531H89.432C89.444 73.1571 89.456 73.0611 89.456 72.9651Z" fill="black"/>
        <path d="M97.987 75.4111C97.891 75.8551 97.783 76.3711 97.735 76.9711H95.948L96.068 76.0351C95.588 76.7431 94.857 77.1021 93.933 77.1021C92.662 77.1021 91.87 76.3591 91.87 75.2671C91.87 73.8881 93.141 73.0241 95.504 73.0241C95.816 73.0241 96.152 73.0481 96.5 73.0851C96.512 73.0131 96.512 72.9401 96.512 72.8811C96.512 72.2571 96.104 71.9221 95.324 71.9221C94.604 71.9221 93.933 72.0771 93.309 72.3901L93.561 70.9511C94.233 70.7111 94.904 70.5911 95.612 70.5911C97.471 70.5911 98.479 71.3711 98.479 72.5591C98.479 72.8461 98.431 73.2301 98.347 73.6621L97.987 75.4111ZM95.637 74.1281C94.486 74.1281 93.886 74.4641 93.886 75.0881C93.886 75.4951 94.198 75.7831 94.678 75.7831C95.422 75.7831 96.094 75.1831 96.249 74.1521C96.032 74.1281 95.828 74.1281 95.637 74.1281Z" fill="black"/>
        <path d="M105.667 76.971H103.52L104.168 73.876C104.216 73.648 104.24 73.444 104.24 73.265C104.24 72.677 103.94 72.354 103.46 72.354C102.608 72.354 102.045 73.025 101.757 74.369L101.206 76.972H99.047L100.366 70.747H102.297L102.057 71.851C102.753 71.059 103.544 70.652 104.408 70.652C105.679 70.652 106.423 71.431 106.423 72.81C106.423 73.206 106.351 73.77 106.195 74.513L105.667 76.971Z" fill="black"/>
        <path d="M202.236 76.98H182.07V89.341H202.236V76.98Z" fill="#095797"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M192.154 77.629C192.008 77.899 191.677 78.289 191.123 78.984C190.478 79.787 190.592 80.859 190.898 81.492C191.246 82.222 191.449 82.828 191.607 83.404C191.83 84.24 191.683 85.359 191.699 85.258H191.14C191.14 83.893 190.714 82.61 189.687 82.041C188.224 81.228 186.781 82.635 187.238 84.094C187.482 84.86 187.912 85.117 188.033 85.117C188.033 85.117 187.834 84.275 188.443 83.844C189.015 83.44 189.724 83.682 190.052 84.168C190.365 84.627 190.413 85.258 190.413 85.258H189.362V86.033H191.473C191.473 86.033 191.418 87.131 190.26 87.021C190.26 87.021 190.403 87.886 191.473 87.437C191.473 87.437 191.418 88.339 192.153 88.796C192.885 88.339 192.831 87.437 192.831 87.437C193.903 87.886 194.046 87.021 194.046 87.021C192.886 87.13 192.831 86.033 192.831 86.033H194.944V85.258H193.893C193.893 85.258 193.944 84.627 194.252 84.168C194.582 83.682 195.289 83.439 195.863 83.844C196.472 84.276 196.273 85.117 196.273 85.117C196.394 85.117 196.824 84.859 197.066 84.094C197.525 82.635 196.08 81.229 194.621 82.041C193.592 82.609 193.168 83.893 193.168 85.258H192.609C192.623 85.36 192.474 84.24 192.701 83.404C192.855 82.828 193.058 82.222 193.408 81.492C193.715 80.859 193.828 79.787 193.183 78.984C192.635 78.289 192.303 77.898 192.154 77.629Z" fill="white"/>
        <path d="M228.283 76.98H208.111V89.341H228.283V76.98Z" fill="#095797"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M218.195 77.629C218.045 77.899 217.716 78.289 217.16 78.984C216.515 79.787 216.629 80.859 216.935 81.492C217.287 82.222 217.488 82.828 217.648 83.404C217.867 84.24 217.722 85.359 217.738 85.258H217.179C217.179 83.893 216.753 82.61 215.724 82.041C214.261 81.228 212.82 82.635 213.279 84.094C213.519 84.86 213.949 85.117 214.072 85.117C214.072 85.117 213.873 84.275 214.484 83.844C215.052 83.44 215.763 83.682 216.089 84.168C216.402 84.627 216.452 85.258 216.452 85.258H215.401V86.033H217.512C217.512 86.033 217.455 87.131 216.301 87.021C216.301 87.021 216.44 87.886 217.512 87.437C217.512 87.437 217.455 88.339 218.194 88.796C218.924 88.339 218.868 87.437 218.868 87.437C219.942 87.886 220.087 87.021 220.087 87.021C218.925 87.13 218.868 86.033 218.868 86.033H220.985V85.258H219.928C219.928 85.258 219.985 84.627 220.289 84.168C220.619 83.682 221.33 83.439 221.904 83.844C222.509 84.276 222.316 85.117 222.316 85.117C222.433 85.117 222.863 84.859 223.101 84.094C223.56 82.635 222.117 81.229 220.656 82.041C219.633 82.609 219.207 83.893 219.207 85.258H218.65C218.664 85.36 218.513 84.24 218.738 83.404C218.894 82.828 219.099 82.222 219.447 81.492C219.752 80.859 219.865 79.787 219.22 78.984C218.674 78.289 218.338 77.898 218.195 77.629Z" fill="white"/>
        <path d="M202.236 95.2271H182.07V107.584H202.236V95.2271Z" fill="#095797"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M192.154 95.875C192.008 96.145 191.677 96.531 191.123 97.23C190.478 98.029 190.592 99.101 190.898 99.738C191.246 100.465 191.449 101.07 191.607 101.65C191.83 102.484 191.683 103.601 191.699 103.5H191.14C191.14 102.139 190.714 100.852 189.687 100.287C188.224 99.471 186.781 100.881 187.238 102.336C187.482 103.102 187.912 103.359 188.033 103.359C188.033 103.359 187.834 102.519 188.443 102.086C189.015 101.682 189.724 101.928 190.052 102.41C190.365 102.871 190.413 103.5 190.413 103.5H189.362V104.277H191.473C191.473 104.277 191.418 105.373 190.26 105.267C190.26 105.267 190.403 106.132 191.473 105.679C191.473 105.679 191.418 106.581 192.153 107.038C192.885 106.581 192.831 105.679 192.831 105.679C193.903 106.132 194.046 105.267 194.046 105.267C192.886 105.372 192.831 104.277 192.831 104.277H194.944V103.5H193.893C193.893 103.5 193.944 102.871 194.252 102.41C194.582 101.928 195.289 101.681 195.863 102.086C196.472 102.52 196.273 103.359 196.273 103.359C196.394 103.359 196.824 103.101 197.066 102.336C197.525 100.881 196.08 99.471 194.621 100.287C193.592 100.851 193.168 102.139 193.168 103.5H192.609C192.623 103.602 192.474 102.484 192.701 101.65C192.855 101.07 193.058 100.464 193.408 99.738C193.715 99.101 193.828 98.029 193.183 97.23C192.635 96.531 192.303 96.145 192.154 95.875Z" fill="white"/>
        <path d="M228.283 95.2271H208.111V107.584H228.283V95.2271Z" fill="#095797"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M218.195 95.875C218.045 96.145 217.716 96.531 217.16 97.23C216.515 98.029 216.629 99.101 216.935 99.738C217.287 100.465 217.488 101.07 217.648 101.65C217.867 102.484 217.722 103.601 217.738 103.5H217.179C217.179 102.139 216.753 100.852 215.724 100.287C214.261 99.471 212.82 100.881 213.279 102.336C213.519 103.102 213.949 103.359 214.072 103.359C214.072 103.359 213.873 102.519 214.484 102.086C215.052 101.682 215.763 101.928 216.089 102.41C216.402 102.871 216.452 103.5 216.452 103.5H215.401V104.277H217.512C217.512 104.277 217.455 105.373 216.301 105.267C216.301 105.267 216.44 106.132 217.512 105.679C217.512 105.679 217.455 106.581 218.194 107.038C218.924 106.581 218.868 105.679 218.868 105.679C219.942 106.132 220.087 105.267 220.087 105.267C218.925 105.372 218.868 104.277 218.868 104.277H220.985V103.5H219.928C219.928 103.5 219.985 102.871 220.289 102.41C220.619 101.928 221.33 101.681 221.904 102.086C222.509 102.52 222.316 103.359 222.316 103.359C222.433 103.359 222.863 103.101 223.101 102.336C223.56 100.881 222.117 99.471 220.656 100.287C219.633 100.851 219.207 102.139 219.207 103.5H218.65C218.664 103.602 218.513 102.484 218.738 101.65C218.894 101.07 219.099 100.464 219.447 99.738C219.752 99.101 219.865 98.029 219.22 97.23C218.674 96.531 218.338 96.145 218.195 95.875Z" fill="white"/>
    </svg>
)
