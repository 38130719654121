import { uiConstants } from 'modules/ui/ui.constants' // _constants

const initialState = {
    menu: false,
}

// all state changes manipulations happen here
export const uiState = function(state = initialState, { type, payload = null }) {
    switch (type) {
        case uiConstants.TOGGLEMENU:
            return {
                ...state,
                menu: payload === null ? !state.menu : payload,
            }

        default:
            return state
    }
}
