import { strategyservice } from 'modules/strategy/strategy.service'
import { strategyConstants } from 'modules/strategy/strategy.constants'

export const strategyActions = {
    list,
}

function list(filters = undefined, callback = () => null) {
    return dispatch => {
        dispatch(request(filters))
        strategyservice.list(filters).then(
            data => {
                dispatch(success(data))
                callback()
            },
            error => {
                dispatch(failure(error))
                callback()
            }
        )
    }

    function request(filters) {
        return { type: strategyConstants.LIST_PENDING, filters }
    }

    function success(payload) {
        return { type: strategyConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: strategyConstants.LIST_FAILURE, error }
    }
}
