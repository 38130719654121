import React from 'react'
export default (props) => (
    <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M29.241 4.01953H20.8099C20.6995 4.01953 20.6092 4.10997 20.6092 4.2205V6.09623C20.6092 6.20677 20.6995 6.2972 20.8099 6.2972H26.1764L19.5085 12.9728C17.8356 11.6832 15.7948 10.9865 13.6502 10.9865C11.0774 10.9865 8.6551 11.9914 6.8384 13.8135C5.0217 15.6357 4.01465 18.0574 4.01465 20.6331C4.01465 23.2089 5.01835 25.634 6.8384 27.4528C8.6551 29.2749 11.0774 30.2797 13.6502 30.2797C16.223 30.2797 18.6453 29.2749 20.462 27.4528C22.282 25.634 23.2857 23.2089 23.2857 20.6331C23.2857 18.4861 22.5898 16.4496 21.3051 14.7748L27.9697 8.10259V13.4652C27.9697 13.5757 28.06 13.6661 28.1704 13.6661H30.044C30.1544 13.6661 30.2447 13.5757 30.2447 13.4652V5.02439C30.2447 4.47172 29.7931 4.01953 29.241 4.01953ZM13.6502 27.7341C9.73909 27.7341 6.55736 24.5487 6.55736 20.6331C6.55736 16.7176 9.73909 13.5322 13.6502 13.5322C17.5613 13.5322 20.743 16.7176 20.743 20.6331C20.743 24.5487 17.5613 27.7341 13.6502 27.7341Z"
            fill="black"
        />
    </svg>
)
