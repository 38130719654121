import { strategyConstants } from 'modules/strategy/strategy.constants' // _constants

const storedData = JSON.parse(localStorage.getItem('strategies'))
const initialState = storedData ? { loaded: false, data: [ ...storedData ]} : { loaded: false, data: []}

// all state changes manipulations happen here
export const strategyState = function(state = initialState, { type, payload }) {
    switch (type) {
        
        case strategyConstants.LIST_PENDING:
            return {
                ...state,
                loaded: false,
            }

        case strategyConstants.LIST_SUCCESS:
            return {
                ...state,
                data: payload.data,
                loaded: true,
            }

        default:
            return state
    }
}
