import React from 'react'
import { connect } from 'react-redux'

import { useStyles } from './Home.styles'
import { getSetting } from 'modules/setting/setting.selectors'

import { Layout } from 'components/Layout'
import { Footer } from 'components/Footer'
import { ImageHeader } from './sections/ImageHeader'
import { Intro } from './sections/Intro'
import { CardList } from './sections/CardList'
import { Contact } from './sections/Contact'
import { getStrategies } from 'modules/strategy/strategy.selectors'

const Content = ({ posts, settings }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Intro
                title={getSetting(settings, 'homepage_title')}
                content={getSetting(settings, 'homepage_text')}
            />
            <CardList posts={posts} />
            <Contact
                title={getSetting(settings, 'contact_title')}
                content={getSetting(settings, 'contact_text')}
            />
            <Footer content={getSetting(settings, 'footer_text')} />
        </div>
    )
}

class HomeComponent extends React.Component {
    render() {
        const { strategies, settings } = this.props
        return (
            <Layout>
                <ImageHeader />
                <Content posts={strategies} settings={settings} />
            </Layout>
        )
    }
}

function mapStateToProps(state, { match }) {
    const { settings } = state
    const strategies = getStrategies(state.strategies)
    
    const { type } = match.params
    return {
        type,
        strategies,
        settings,
    }
}


export const Home = connect(mapStateToProps)(HomeComponent)
