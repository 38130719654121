import { filterConstants } from './filter.constants' // _constants

const storedData = JSON.parse(localStorage.getItem('filters'))
const initialState = storedData
    ? { ...storedData }
    : {
        agee: true,
        lgbtq: true,
        homme: true,
        limitation: true,
        immigrant: true,
    }

// all state changes manipulations happen here
export const filterState = function(state = initialState, { type, payload }) {
    switch (type) {
        case filterConstants.UPDATE:
            return { ...state, ...payload }
        default:
            return state
    }
}
