import React from 'react'
import BaseButton from '@material-ui/core/Button'
import { useStyles } from './Button.styles'
import { _, useHistory } from 'helpers'


export const ButtonList = props => {
    const classes = useStyles(props)
    return <div className={classes.buttonlist}>{props.children}</div>
}

export const Button = props => {
    const classes = useStyles(props)
    const history = useHistory()

    const {
        label,
        variant = 'outlined',
        color = 'primary',
        size = 'medium',
        to,
        onClick = () => history.push(to),
        ...rest
    } = props


    return (
        <BaseButton
            className={classes.button}
            variant={variant}
            color={color}
            size={size}
            onClick={onClick}
            {...rest}
        >{label}</BaseButton>
    )
}

export const BackButton = () => {
    const history = useHistory()
    
    return (
        <Button
            onClick={history.goBack}
            label={_('common.back')}
        />
    )
}
