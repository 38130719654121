import { settingConstants } from 'modules/setting/setting.constants'
import { settingService } from 'modules/setting/setting.service'

export const settingActions = {
    list,
}

function list(filters = undefined, callback = () => null) {
    return dispatch => {
        dispatch(request(filters))
        settingService.list(filters).then(
            data => {
                dispatch(success(data))
                callback()
            },
            error => {
                dispatch(failure(error))
                callback()
            }
        )
    }

    function request(filters) {
        return { type: settingConstants.LIST_PENDING, filters }
    }

    function success(payload) {
        return { type: settingConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: settingConstants.LIST_FAILURE, error }
    }
}
