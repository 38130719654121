import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        background: 'white',
        padding: theme.spacing(6, 2, 10),
        textAlign: 'center',
    },
    text: {
        margin: theme.spacing(3, 0),
    },
    logo: {
        margin: theme.spacing(0, 3),
    }
}))
