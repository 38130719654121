import { httpClient, save } from 'helpers'

export const strategyservice = {
    list,
}

const resource = 'strategies'

function list(filters = undefined) {
    return httpClient.GET(resource + (filters ? filters : ''), {}, [save('strategies')])
}
