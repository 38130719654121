import axios from 'axios'

import config from 'config'
import { _, } from 'helpers'

/* API Config */

const baseConfig = {
    baseURL: config.apiBaseURL,
    timeout: config.requestTimeout,
}


/* Functions */
const makeUrl = url => config.apiUrl + url

function getUrl(config) {
    if (config.baseURL) {
        return config.url.replace(config.baseURL, '/')
    }
    return config.url
}


/* Axios init */
const axiosClient = axios.create(baseConfig)

// Add Interceptors
if (config.debugMode && false) {
    axiosClient.interceptors.request.use(
        config => {
            console.log(
                '%c ' + config.method.toUpperCase() + ' - ' + getUrl(config) + ':',
                'color: #0086b3; font-weight: bold',
                config
            )
            return config
        },
        error => {
            return Promise.reject(error)
        }
    )

    axiosClient.interceptors.response.use(
        response => {
            console.log(
                '%c ' + response.status + ' - ' + getUrl(response.config) + ':',
                'color: #008000; font-weight: bold',
                response
            )
            
            return response
        },

        error => {
            if (error.response && error.response.status) {
                console.log(
                    '%c ' +
                        error.response.status +
                        ' - ' +
                        getUrl(error.response.config) +
                        ':',
                    'color: #a71d5d; font-weight: bold',
                    error.response
                )
            }
            else if (error.code === 'ECONNABORTED') {
                console.log('%c Request timeout.', 'color: #FFA500; font-weight: bold')
            }
            else {
                console.log('%c Response undefined.', 'color: #ff0000; font-weight: bold')
            }
            return Promise.reject(error)
        }
    )
}

/* Requests */

const request = function (method, url, customOptions = {}, handlers = []) {
    const baseHeaders = { 'Content-Type': 'application/json' }
    const options = {
        ...customOptions,
        method,
        url: makeUrl(url),
        headers: {
            ...baseHeaders,
            ...customOptions.headers,
        }
    }

    return axiosClient(options)
        .then(handleResponse)
        .then(response => {
            handlers.forEach(handler => handler(response))
            return response
        })
        .catch(handleError)
}

export const httpClient = {
    GET: (...args) =>  request('GET', ...args),
    PUT: (...args) =>  request('PUT', ...args),
    PATCH: (...args) =>  request('PATCH', ...args),
    POST: (...args) =>  request('POST', ...args),
    DELETE: (...args) =>  request('DELETE', ...args)
}


/* Response handlers */

export function handleResponse(response) {
    return response.data
}

export function handleError(error) {
    const { response } = error
    const data = {
        text: undefined,
        code: 0,
    }

    // timeout caused by config.requestTimeout setting
    if (error.code === 'ECONNABORTED') {
        data.text = _('alert.408')
        data.code = 408
        return Promise.reject(data)
    }

    // should unknown errors happen...
    if (response === undefined) {
        data.text = _('alert.0')
        // capture(new Error(data.text), 'API') // send to sentry
        return Promise.reject(data)
    }

    if (response.statusText !== 'OK') {
        switch (response.status) {
            case 401:
                data.text = _('alert.401')
                break;
            case 404:
                data.text = _('alert.404')
                break
            case 429:
                data.text = _('alert.429')
                break
            case 500:
                data.text = _('alert.500')
                // const extras = {
                //     ...response.data,
                //     trace: response.data.trace.slice(0, 12),
                // }
                // capture(new Error(response.data.message), 'API', extras)
                break
            default: 
                break
        }
        
        data.code = response.status

        !data.text &&
            (data.text = (response.data && response.data.error)
            || (response.data && response.data.errors)
            || (response.data && response.data.message)
            || response.message
            || response.statusText)

        return Promise.reject(data)
    }

    return response.data
}

// store the resource data in the same named entry in localstorage
export const save = resource => response => {
    localStorage.setItem(resource, JSON.stringify(response.data))
    return response
}
