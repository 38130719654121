import React from 'react'
import Typography from '@material-ui/core/Typography'

import { specialStyles } from './Title.styles'
import Bulb from 'components/Icon/Bulb'

export const SpecialTitle = ({ children, lines = false, center = false, forwardRef }) => {
    const classes = specialStyles()
    const classNames = [
        classes.root,
        ...(lines ? [classes.lines] : []),
        ...(center ? [classes.center] : []),
    ].join(' ')

    return <Typography variant="h2" className={classNames} ref={forwardRef}>
        <Bulb className={classes.bulb} />
        {children}
    </Typography>
}
