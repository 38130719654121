import React from 'react'

import LogoCiusss from 'components/Icon/LogoCiusss'
import { Markdown } from 'components/Markdown'
import { useStyles } from './Footer.styles'

import { _ } from 'helpers/lang'

export const Footer = ({ content }) => {
    const classes = useStyles()
    return (
        <footer className={classes.root}>
            
            <div className={classes.text}>
                <LogoCiusss className={classes.logo} />
                {/* <Logo className={classes.logo} /> */}
            </div>
            
            <div className={classes.text}>
                <Markdown source={_('app.agency')} />
                <p>{_('app.copyright')}</p>
            </div>
            
            {/* <div className={classes.text}>
                <Markdown source={content} />
            </div> */}
        </footer>
    )
}
