import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'

import { Definition } from 'components/Definition'
import { Markdown } from 'components/Markdown'
import { useStyles } from './Note.styles'

import { _ } from 'helpers/lang'

export const Note = ({
    content,
}) => {
    const classes = useStyles()

    const Button = props => {
        return <IconButton
            className={classes.root}
            variant="outlined"
            color="primary"
            aria-label={_('app.note')} 
            {...props}
        >
            <AddIcon />
        </IconButton>
    }

    return <>        
        <Definition TriggerComponent={Button} >
            <Markdown source={content} />
        </Definition>
    </>
}