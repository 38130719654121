import React from 'react'
export default (props) => (
    <svg
        width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M34.375 11.9141H24.375V7.5C24.375 6.80859 23.8164 6.25 23.125 6.25H7.1875V4.6875C7.1875 4.51562 7.04688 4.375 6.875 4.375H4.6875C4.51562 4.375 4.375 4.51562 4.375 4.6875V35.3125C4.375 35.4844 4.51562 35.625 4.6875 35.625H6.875C7.04688 35.625 7.1875 35.4844 7.1875 35.3125V25H16.875V29.4141C16.875 30.1055 17.4336 30.6641 18.125 30.6641H34.375C35.0664 30.6641 35.625 30.1055 35.625 29.4141V13.1641C35.625 12.4727 35.0664 11.9141 34.375 11.9141ZM7.1875 22.1875V9.0625H21.5625V22.1875H7.1875ZM32.8125 27.8516H19.6875V25H24.0625C24.2344 25 24.375 24.8594 24.375 24.6875V14.7266H32.8125V27.8516Z" fill="black"/>
        <rect x="7" y="11" width="15" height="1" fill="black"/>
        <rect x="22" y="17" width="13" height="1" fill="black"/>
        <rect x="7" y="13" width="15" height="1" fill="black"/>
        <rect x="22" y="19" width="13" height="1" fill="black"/>
        <rect x="7" y="15" width="15" height="1" fill="black"/>
        <rect x="22" y="21" width="13" height="1" fill="black"/>
        <rect x="7" y="17" width="15" height="1" fill="black"/>
        <rect x="22" y="23" width="13" height="1" fill="black"/>
        <rect x="7" y="19" width="15" height="1" fill="black"/>
        <rect x="19" y="25" width="16" height="1" fill="black"/>
    </svg>
)