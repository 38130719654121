import { httpClient, save } from 'helpers'

export const settingService = {
    list,
}

const resource = 'settings'

function list(filters = undefined) {
    return httpClient.GET(resource + (filters ? filters : ''), {}, [save(resource)])
}
