import React from 'react'

import { useStyles } from './CardList.styles'
import { Card } from 'components/Card'
import { Title } from 'components/Title'
import { Inner } from 'components/Inner'
import { getColor } from 'utils/theme'
import { getNumberText } from 'utils/functions'

import { _ } from 'helpers/lang'

export const CardList = ({ posts }) => {
    const classes = useStyles()

    const Cards = () => posts.map((c, key) => <div key={key} className={classes.col}>
        <Card number={getNumberText(c.short_title)} color={getColor(key)} post={c}><strong>{c.name}</strong><p>{c.preview}</p></Card>
    </div>)

    return (
        <div className={classes.root}>
            <Inner>
                <Title lines center>{_("app.strategie")}</Title>
                <div className={classes.flex}>
                    <Cards />
                </div>
            </Inner>
        </div>
    )
}
