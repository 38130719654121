import React from 'react'
import Typography from '@material-ui/core/Typography'

import { Markdown } from 'components/Markdown'
import PictoAgee from 'components/Icon/PictoAgee'
import PictoHomme from 'components/Icon/PictoHomme'
import PictoLGBTQ from 'components/Icon/PictoLGBTQ'
import PictoHandicap from 'components/Icon/PictoHandicap'
import PictoImmigrant from 'components/Icon/PictoImmigrant'
import { Note } from 'components/Note'
import { useStyles } from './FilteredList.styles'
import { _ } from '../../helpers/lang'

export const FilteredList = ({ filters, selectedIndex, selectedPost, type }) => {
    const classes = useStyles()

    const titles = [
        { key: 'agee', title: _('app.agee'), picto: PictoAgee},
        { key: 'homme', title: _('app.homme'), picto: PictoHomme},
        { key: 'immigrant', title: _('app.immigrant'), picto: PictoImmigrant},
        { key: 'lgbtq', title: _('app.lgbtq'), picto: PictoLGBTQ},
        { key: 'limitation', title: _('app.limitation'), picto: PictoHandicap},
    ]

    const data = titles.map((v, index) => {
        return {
            key: v.key,
            title: v.title,
            Picto: v.picto,
            violence: selectedPost[`violence_${v.key}`],
            facteur: selectedPost[`facteur_${v.key}`],
            violence_note: selectedPost[`violence_${v.key}_note`],
            facteur_note: selectedPost[`facteur_${v.key}_note`],
        }
    }).filter(v => filters[v.key] === true) // apply filters from menu

    return data.map(d => {
        const note = d[`${type}_note`]
        return <div className={classes.root} key={`${selectedIndex}-${d.key}`}>

            {note && <Note content={note} />}

            <Typography variant="h3" className={classes.title}>
                <d.Picto className={classes.picto} />
                {d.title}
            </Typography>

            <div className={classes.content}>
                <Markdown source={d[type]} />
            </div>
        </div>
    })
}
