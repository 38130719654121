import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    '@global': {
        '#simple-popper .markdown a': {
            color: 'white',
            textDecoration: 'underline',
        },
    },
    root: {
        margin: '1rem auto',
        maxHeight: '800px',
        width: 'calc(100% - 0.75rem)',
        maxWidth: '600px',
        zIndex: 12,
    },
    content: {
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        padding: '2em',
        color: 'white',
        background: 'rgba(9, 87, 151, 0.95)',
    },
    title: {
        fontSize: 18,
        fontWeight: 900,
        textTransform: 'capitalize',
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.primary.main}`,
        height: '1.2em',
        width: '1.2em',
        fontSize: '2rem',

        cursor: 'pointer',
        margin: '0',
        display: 'inline-flex',
        outline: '0',
        padding: '0',
        alignItems: 'center',
        userSelect: 'none',
        borderRadius: '0',
        verticalAlign: 'middle',
        justifyContent: 'center',
        textDecoration: 'none',
        '-moz-appearance': 'none',
        '-webkit-appearance': 'none',
        '-webkit-tap-highlight-color': 'transparent',
    }
}))