import React from 'react'
export default (props) => (
    <svg
        width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M16.3398 11.2477C18.2728 11.2477 19.8398 9.68068 19.8398 7.74768C19.8398 5.81468 18.2728 4.24768 16.3398 4.24768C14.4068 4.24768 12.8398 5.81468 12.8398 7.74768C12.8398 9.68068 14.4068 11.2477 16.3398 11.2477Z" fill="black"/>
        <path d="M30.3048 26.6547C30.2255 26.2581 30.0113 25.9012 29.6987 25.6447C29.386 25.3882 28.9942 25.2479 28.5898 25.2477H21.357L20.6063 19.9977H28.5898V16.4977H20.1075L19.8223 14.4992C19.7625 14.0823 19.5544 13.701 19.2361 13.4252C18.9179 13.1494 18.5109 12.9976 18.0898 12.9977H16.3398C16.0892 12.9985 15.8417 13.053 15.614 13.1574C15.3862 13.2619 15.1834 13.4139 15.0193 13.6032C14.8552 13.7925 14.7335 14.0148 14.6624 14.2551C14.5913 14.4953 14.5725 14.748 14.6073 14.9962L16.142 25.7447C16.2647 26.577 16.6818 27.3378 17.3176 27.8888C17.9534 28.4398 18.7657 28.7445 19.607 28.7477H27.1548L28.623 36.0907C28.7875 36.9079 29.505 37.4977 30.3398 37.4977H35.5898V33.9977H31.773L30.3048 26.6547Z" fill="black"/>
        <path d="M22.4823 31.3726C21.1891 33.9556 18.5448 35.7476 15.4648 35.7476C13.377 35.7453 11.3753 34.9149 9.89894 33.4385C8.42259 31.9622 7.59216 29.9605 7.58984 27.8726C7.59279 26.3046 8.06346 24.7731 8.94165 23.474C9.81984 22.175 11.0656 21.1675 12.5196 20.5804L12.0138 17.0366C7.42359 18.5014 4.08984 22.8046 4.08984 27.8726C4.08984 34.1446 9.19284 39.2476 15.4648 39.2476C17.4157 39.2449 19.3331 38.7402 21.0326 37.7822C22.732 36.8241 24.1564 35.4449 25.1686 33.7771L24.6891 31.3726H22.4823Z" fill="black"/>
    </svg>
)