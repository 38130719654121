import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        overflow: 'visible',
        padding: '2rem 1rem 1.5rem',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
        border: '1px solid #FFFFFF',
        borderRadius: 10,
        
        '&--blue': {
            background: theme.palette.blue.light,
        },
        '&--cyan': {
            background: theme.palette.cyan.light,
        },
        '&--yellow': {
            background: theme.palette.yellow.light,
        },
        '&--red': {
            background: theme.palette.red.light,
        },
        '&--green': {
            background: theme.palette.green.light,
        },
        '&--pink': {
            background: theme.palette.pink.light,
        },
    },
    border: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        borderRadius: 8,
        zIndex: 0,
        '&:hover': {
            boxShadow: 'inset 0px 0px 0px 0.5rem rgba(255,255,255,1)',
        },
        boxShadow: 'inset 0px 0px 0px 0px rgba(255,255,255,1)',
    },
    link: {
        textDecoration: 'none',
    },
    cardContent: {
        color: 'black',
    },
}))