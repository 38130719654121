import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    listItemRoot: {
        marginBottom: theme.spacing(-2),
        background: theme.palette.primary[900],
        color: 'white',
        '&:hover': {
            background: theme.palette.primary[800],
        }
    },
    listItemIcon: {
        color: 'white',
    }
}))
