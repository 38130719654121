import React from 'react'
import Popper from '@material-ui/core/Popper'
import { debounce } from 'lodash'
// import CloseIcon from '@material-ui/icons/Close'

import { useStyles } from './Definition.styles'

// value.shift() // remove the title part of the first line
// children.shift() // remove the first line of the children
// children = [ value, ...children ] // add the first line without the title backint

export const Definition = ({ title, TriggerComponent, children, mouseover = false }) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
  
    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }
  
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popper' : undefined

    const interactionProps =
        mouseover ? {
            onTouchStart: e => debounce(() => handleClick(e), 250),
            onMouseEnter: handleClick,
            onMouseLeave: handleClick,
        } : {
            onClick: handleClick,
        }
  
    return (
        <>
            <TriggerComponent
                aria-describedby={id}
                {...interactionProps}
            />
            <Popper
                id={id}
                open={open}
                className={classes.root}
                anchorEl={anchorEl}
                placement="top-start"
            >
                <div className={classes.content}>
                    {/* <button
                        onClick={handleClick}
                        className={classes.closeButton}
                    >
                        <CloseIcon />
                    </button> */}
                    {title && <p className={classes.title}>{title}</p>}
                    {children}
                </div>
            </Popper>
        </>
    )
}
