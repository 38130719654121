import { Lang } from 'helpers'

export const url = (...args) => {
    const url = [Lang.getLang(), ...args].join('/');

    if (url.startsWith('/') || url.startsWith('http')) {
        return url;
    }

    return '/' + url;
};

export const langSwitcherUrl = () => {
    const lang = Lang.getLang() === 'fr' ? 'en' : 'fr'

    const url = [lang].join('/');

    if (url.startsWith('/') || url.startsWith('http')) {
        return url;
    }

    return '/' + url;
}

export const appUrls = {
    strategy: t => url('s', t),
    page: t => url('p', t),
}

export const apiUrls = {
    auth: {
        group: 'auth',
        user: 'auth/user',
        login: 'auth/login',
        logout: 'auth/logout',
        refresh: 'auth/refresh',
        email: status => `auth/email/${status}`,
        password: {
            forgot: 'auth/password/forgot',
            reset: token => `auth/password/reset/${token}`,
        },
        register: 'auth/register',
    },
}
