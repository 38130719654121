
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin: theme.spacing(1, 0, 10),
    },
    margins: {
        position: 'relative',
        margin: theme.spacing(10, 0, 5),
    },
    titleBlack: {
        padding: theme.spacing(3),
        margin: theme.spacing(2, -2),
        background: 'black',
        color: 'white',
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(2, 0),
        },
    },
    titleBlue: {
        padding: theme.spacing(3),
        background: theme.palette.primary.main,
        color: 'white',
    },
    buttonContainer: {
        margin: theme.spacing(2, 0, 1),
        display: 'flex',
    
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    filterButton: {
        flexGrow: 1,
        padding: theme.spacing(2, 2.5),
        margin: theme.spacing(0.5, 1, 0.5, 0),
        color: 'white',
        background: theme.palette.primary.main,

        '&:hover': {
            background: theme.palette.primary.main,
        },

        [theme.breakpoints.down('xs')]: {
            flexGrow: 1,
            margin: theme.spacing(0.5, 0),
        },
    },
}))
