import React, { useRef } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import BaseButton from '@material-ui/core/Button'

import { Pageheader } from 'components/Pageheader'
import { Layout } from 'components/Layout'
import { Markdown } from 'components/Markdown'
import { SpecialTitle } from 'components/Title'
import Diagram from 'components/Diagram/Diagram'
import { FilteredList } from 'components/FilteredList'
import { AnchorMenu } from 'components/AnchorMenu'
import { Note } from 'components/Note'
import { Footer } from 'components/Footer'

import { getNumberText } from 'utils/functions'
import { uiActions } from 'modules/ui/ui.actions'
import { getLoaded, getStrategies, getStrategy, getStrategyIndex } from 'modules/strategy/strategy.selectors'
import { getSetting } from 'modules/setting/setting.selectors'
import { _ } from 'helpers/lang'
import { useStyles } from './Strategy.styles'

const Content = ({
    filters,
    selectedPost,
    selectedIndex,
    toggleMenu,
}) => {
    const { 
        name,
        short_title,
        intro,
        intro_title,
        body,
        questions,
        questions_title,
        table_a_title,
        table_b_title,
    } = selectedPost

    const classes = useStyles()

    const anchors = [
        { ref: useRef(null), title: intro_title },
        { ref: useRef(null), title: _('app.keep_in_mind') },
        { ref: useRef(null), title: questions_title },
        { ref: useRef(null), title: table_a_title },
        { ref: useRef(null), title: table_b_title },
    ]

    return <section className={classes.root}>
        <Pageheader title={name} number={selectedIndex} numberText={getNumberText(short_title)}>
            <div className={classes.buttonContainer}>
                <BaseButton onClick={toggleMenu} classes={{ root: classes.filterButton, label: classes.buttonLabel }}>{_('app.filters')}</BaseButton>
                <AnchorMenu anchors={anchors} />
            </div>
        </Pageheader>

        <Diagram />

        {anchors[0].title && intro ?
            <>
                <Typography variant="h3" align="center" ref={anchors[0].ref}>{anchors[0].title}</Typography>
                <Markdown source={intro} />
            </> : null
        }

        {anchors[1].title && body ? 
            <>
                <SpecialTitle forwardRef={anchors[1].ref}>{anchors[1].title}</SpecialTitle>
                <Markdown source={body} />
            </> : null
        }
        
        {anchors[2].title && questions ?
            <>
                <Typography variant="h3" align="center" className={classes.titleBlack} ref={anchors[2].ref}>{anchors[2].title}</Typography>
                <Markdown source={questions} />
            </> : null
        }
        
        {anchors[3].title && 
            <div className={classes.margins}>
                <Typography variant="h3" align="center" className={classes.titleBlue} ref={anchors[3].ref}>{anchors[3].title}</Typography>
                {selectedPost.table_a_note && <Note content={selectedPost.table_a_note} />}
                <FilteredList filters={filters} selectedIndex={selectedIndex} selectedPost={selectedPost} type="violence" />
            </div>
        }
        
        {anchors[4].title && 
            <div className={classes.margins}>
                <Typography variant="h3" align="center" className={classes.titleBlue} ref={anchors[4].ref}>{anchors[4].title}</Typography>
                {selectedPost.table_b_note && <Note content={selectedPost.table_b_note} />}
                <FilteredList  filters={filters} selectedIndex={selectedIndex} selectedPost={selectedPost} type="facteur" />
            </div>
        }
        
    </section>
}

class StrategyComponent extends React.Component {

    render() {
        const {
            filters,
            selectedPost,
            selectedIndex,
            toggleMenu,
            settings,
        } = this.props

        return (
            selectedPost && <Layout title={selectedPost.name} container={true}>
                <Content
                    filters={filters}
                    selectedPost={selectedPost}
                    selectedIndex={selectedIndex}
                    toggleMenu={toggleMenu}
                />
                <Footer content={getSetting(settings, 'footer_text')} />
            </Layout>
        )
    }
}

function mapStateToProps(state, router) {
    let { ui, filters, settings } = state
    const { slug } = router.match.params

    const strategies = getStrategies(state.strategies)
    const loaded = getLoaded(state.strategies)
    const selectedIndex = getStrategyIndex(state.strategies, slug)

    const hasPost = selectedIndex >= 0

    if (!hasPost && loaded) {
        router.history.push('/not-found')
    }

    const selectedPost = hasPost ? getStrategy(state.strategies, slug)[0] : false

    return {
        ui,
        filters,
        strategies,
        selectedPost,
        selectedIndex,
        settings,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleMenu: () => dispatch(uiActions.toggleMenu())
    }
}
export const Strategy = connect(mapStateToProps, mapDispatchToProps)(StrategyComponent)
