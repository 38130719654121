import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import BaseButton from '@material-ui/core/Button' 

import { scrollToRef } from 'utils/navigation'
import { useStyles } from './AnchorMenu.styles'
import { truncate } from 'utils/functions'
import { _ } from 'helpers/lang'

export const AnchorMenu = ({ anchors }) => {

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleSelect = ref => {
        setAnchorEl(null)
        scrollToRef(ref)
    }

    return <>
        <BaseButton
            classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={event => setAnchorEl(event.currentTarget)}
        >{_('app.navigate')}</BaseButton>

        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {anchors.map((anchor, i) => anchor.title
                ? <MenuItem key={i} onClick={() => handleSelect(anchor.ref)}>{truncate(anchor.title, 50)}</MenuItem>
                : null)}
        </Menu>
    </>
}