import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        boxSizing: 'content-box',
    },
    flex: {
        marginTop: theme.spacing(8),
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
    },
    col: {
        display: 'flex',
        marginBottom: '3rem',
        [theme.breakpoints.up('sm')]: {
            width: 'calc(50% - 1rem)',
        },
        [theme.breakpoints.up('md')]: {
            width: 'calc(33% - 1rem)',
        },
    },
    diagramme: {
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            textAlign: 'center',
            margin: 'auto',
            maxWidth: '100%',
        },
    }
}))
