import { filterConstants } from 'modules/filter/filter.constants'

export const filterActions = {
    update,
}

function update(data) {
    return dispatch => {
        dispatch(request(data))
    }

    function request(data) {
        return { type: filterConstants.UPDATE, payload: data }
    }

}
