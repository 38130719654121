import React from 'react'
export default (props) => (
    <svg
        width="39"
        height="39"
        viewBox="0 0 39 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0)">
            <path
                d="M37.3377 16.8814H35.6515C34.6879 13.9874 32.0381 12.0581 28.9066 12.0581C25.775 12.0581 23.1253 14.2286 22.1617 17.1226C21.4391 16.1579 20.4755 15.6756 19.2711 15.6756C18.0666 15.6756 17.1031 16.1579 16.3804 17.1226C15.4169 14.2286 12.7671 12.0581 9.63553 12.0581C6.50398 12.0581 3.85421 14.2286 2.89066 16.8814H1.20444C0.481777 16.8814 0 17.3637 0 18.0872C0 18.8107 0.481777 19.2931 1.20444 19.2931H2.40888C2.40888 23.3929 5.54043 26.528 9.63553 26.528C13.2489 26.528 16.1395 23.8752 16.8622 20.4989C17.5848 20.4989 18.0666 20.0166 18.0666 19.2931C18.0666 18.5696 18.5484 18.0872 19.2711 18.0872C19.9937 18.0872 20.4755 18.5696 20.4755 19.2931C20.4755 20.0166 20.9573 20.4989 21.6799 20.4989C22.1617 23.8752 25.2933 26.528 28.9066 26.528C33.0017 26.528 36.1332 23.3929 36.1332 19.2931H37.3377C38.0604 19.2931 38.5421 18.8107 38.5421 18.0872C38.5421 17.3637 38.0604 16.8814 37.3377 16.8814ZM9.63553 24.1164C6.98576 24.1164 4.81777 21.9459 4.81777 19.2931C4.81777 16.6402 6.98576 14.4698 9.63553 14.4698C12.2853 14.4698 14.4533 16.6402 14.4533 19.2931C14.4533 21.9459 12.2853 24.1164 9.63553 24.1164ZM28.9066 24.1164C26.2568 24.1164 24.0888 21.9459 24.0888 19.2931C24.0888 16.6402 26.2568 14.4698 28.9066 14.4698C31.5564 14.4698 33.7244 16.6402 33.7244 19.2931C33.7244 21.9459 31.5564 24.1164 28.9066 24.1164Z"
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="38.5421" height="38.5864" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
