import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        width: '150px',
        margin: '12px auto 24px',
    },
    img: {
        maxWidth: '100%',
    },
    offline: {
        padding: theme.spacing(1),
        color: theme.palette.secondary[100],
        background: 'rgba(0,0,0,.5)',
    }
}))