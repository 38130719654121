import { alertConstants } from 'modules/alert/alert.constants'

export function alertState(state = [], { type, message, options }) {
    switch (type) {
        case alertConstants.SUCCESS:
            return [
                ...state,
                {
                    type: 'success',
                    message,
                    options,
                }
            ]
            
        case alertConstants.ERROR:
            return [
                ...state,
                {
                    type: 'error',
                    message,
                    options,
                }
            ]

        case alertConstants.WARNING:
            return [
                ...state,
                {
                    type: 'warning',
                    message,
                    options,
                }
            ]

        case alertConstants.INFO:
            return [
                ...state,
                {
                    type: 'info',
                    message,
                    options,
                }
            ]

        case alertConstants.CLEAR:
            return []
            // return state.slice(1, action.index)

        default:
            return state
    }
}
