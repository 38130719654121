import React from 'react'

import { Topbar } from 'components/Topbar'
import { Previous } from 'components/Previous'
import { useStyles } from './Layout.styles'
import { Inner } from 'components/Inner/Inner'

const Content = ({ children }) => {
    const classes = useStyles()
    return <div className={classes.content}>
        <div className={classes.contentbg} />
        <Inner paddingTop={false} paddingBottom={false}>
            {children}
        </Inner>
    </div>
}

export const Layout = props => {
    const {
        previous,
        // menu,
        // title,
        children,
        disabledSearch,
        container } = props

    const classes = useStyles(props)

    return (
        <div className={classes.root}>
            <Topbar disabledSearch={disabledSearch} />
            {previous && <Previous url={previous} />}
            {container && <Content>{children}</Content>}
            {!container && children}
        </div>
    )
}
