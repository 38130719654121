import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

export const useStyles = makeStyles(theme => ({
    search: {
        position: 'relative',
        // borderRadius: theme.shape.borderRadius,
        // '&:hover': {
        //     backgroundColor: fade(theme.palette.common.black, 0.1),
        // },
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginLeft: 0,
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
        },
    },
    searchIcon: {
        width: theme.spacing(6),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
    },
    inputRoot: {
        color: 'inherit',
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    inputInput: {
        position: 'absolute',
        top: -2,
        right: -4,
        padding: theme.spacing(1.5, 1.5, 1.5, 3),
        // transition: theme.transitions.create('width'),
        opacity: 0,
        transition: theme.transitions.create('opacity'),
        width: 0,
        borderRadius: theme.shape.borderRadius,
        '&:focus': {
            width: 200,
            opacity: 1,
            backgroundColor: fade(theme.palette.secondary[200], 1),
        },
    },
}))
