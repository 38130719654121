import { makeStyles } from '@material-ui/core/styles'

export const baseStyles = makeStyles(theme => ({
    root: {
    },
    lines: {
        position: 'relative',
        '&::before': {
            borderTop: '2px solid #dfdfdf',
            content: "''",
            margin: '0 auto', /* this centers the line to the full width specified */
            position: 'absolute', /* positioning must be absolute here, and relative positioning must be applied to the parent */
            top: '50%',
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            zIndex: 0,
        },
    },
    span: {
        position: 'relative',
        background: '#fff', 
        padding: theme.spacing(0, 4), 
        zIndex: 1,
    },
    center: {
        textAlign: 'center',
    },
}))


export const specialStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        margin: theme.spacing(2, 0, 2, 2),
        padding: theme.spacing(2),
        background: '#FFEAA7',
        color: theme.palette.primary.main,

        fontFamily: 'Permanent Marker',
        fontSize: '22px',
        fontWeight: '400',
        lineHeight: '1.3em',
        letterSpacing: '0em',
        textAlign: 'center',
    },
    bulb: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '-1.1em',
        margin: 'auto',
        padding: theme.spacing(0.9),
        height: '2.2em',
        width: '2.2em',
        background: 'white',
        borderRadius: '100%',
    }
}))