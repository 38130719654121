import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        boxSizing: 'content-box',
    },
    flex: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    col: {
        // padding: theme.spacing(10, 5),
        [theme.breakpoints.up('md')]: {
            width: 'calc(50% - 3rem)',
        },
    },
    diagramme: {
        display: 'block',
        maxWidth: '100%',
        margin: 'auto 0 0 auto',
        [theme.breakpoints.down('sm')]: {
            margin: 'auto',
        },
    },
    title: {
        fontSize: '1.8em',
    }
}))
