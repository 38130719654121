import React from 'react'
import { useStyles } from './Contact.styles'
import { Inner } from 'components/Inner'
import { Markdown } from 'components/Markdown/index'

export const Contact = ({ title, content }) => {
    const classes = useStyles()

    return (
        <div id="contact" className={classes.root}>
            <Inner>
                <h2>{title}</h2>
                <Markdown source={content} />
            </Inner>
        </div>
    )
}
