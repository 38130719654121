// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

import { alertActions } from 'modules/alert/alert.actions'
import { store } from 'helpers'
import config from 'config'


const isLocalhost = Boolean(
    window.location.hostname.includes('lndo.site') ||
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)


const swConfig = {
    onUpdate: registration => {
        // At this point, the updated precached content has been fetched,
        // but the previous service worker will still serve the older
        // content until all client tabs are closed.

        // delayed update
        // store.dispatch(alertActions.info(
        //     'Mise à jour disponible. Veuillez relancer l\'application.'
        // ))

        // auto update
        registration.unregister().then(() => {
            window.location.reload()
        })
    },
    onSuccess: registration => {
        // At this point, everything has been precached.
        // It's the perfect time to display a
        // "Content is cached for offline use." message.

        store.dispatch(alertActions.info('L\'application a été mise à jour !'))
    },
}


const serviceWorker = {
    register,
    unregister,
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.unregister()
serviceWorker.register(swConfig)


// FUNCTIONS

function setOnline() {
    const updated = localStorage.getItem('offline') === "true"
    localStorage.setItem('offline', false)
    if (updated) window.location.reload()
}

function setOffline() {
    store.dispatch(alertActions.info('Mode hors-ligne.'))
    const updated = localStorage.getItem('offline') !== "true"
    localStorage.setItem('offline', true)
    if (updated) window.location.reload()
}

function testConnection() {
    return fetch(config.apiUrl + 'status', { method: 'HEAD', mode: 'no-cors' })
}

function initService() {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
    if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorkerLOCALHOST(swUrl, config)
    } else {
        checkValidServiceWorkerLIVE(swUrl, config)
    }
}

function register(config) {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
        
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            return
        }

        window.addEventListener('load', initService)
        window.addEventListener('online', initService)
        window.addEventListener('offline', initService)
    }
}

function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister()
        })
    }
}

function registerValidSW(swUrl, config) {
    navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
            registration.onupdatefound = () => {
                const installingWorker = registration.installing
                if (installingWorker == null) {
                    return
                }
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            if (config && config.onUpdate) {
                                config.onUpdate(registration)
                            }
                        } else {
                            if (config && config.onSuccess) {
                                config.onSuccess(registration)
                            }
                        }
                    }
                }
            }
        })
        .catch(error => {
            console.error('Error during service worker registration:', error)
        })
}

function checkValidServiceWorkerLIVE(swUrl, config) {
    testConnection()
        .then(resp => {
            setOnline()            
            registerValidSW(swUrl, config)
        })
        .catch(err => {
            setOffline()
        })
    // fetch(swUrl)
    //     .then(response => {
    //         setOnline()            
    //         registerValidSW(swUrl, config)
    //     })
    //     .catch(() => {
    //         setOffline()
    //     })
}

function checkValidServiceWorkerLOCALHOST(swUrl, config) {
    
    // Add some additional logging to localhost, pointing developers to the
    // service worker/PWA documentation.
    navigator.serviceWorker.ready.then(() => {     
        console.log('This web app is being served cache-first by a service worker.')
    })

    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl)
        .then(response => {
            setOnline()
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get('content-type')
            if (
                response.status === 404 ||
                (contentType != null &&
                    contentType.indexOf('javascript') === -1)
            ) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then(registration => {
                    registration.unregister().then(() => {
                        window.location.reload()
                    })
                })
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl, config)
            }
        })
        .catch(() => {
            setOffline()
        })
}
