import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import { _, useHistory } from 'helpers'

import { useStyles } from './Previous.styles'

export const Previous = ({ url }) => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <List>
            <ListItem classes={{ root: classes.listItemRoot }} button onClick={history.push(url)}>
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <ChevronLeftIcon />
                </ListItemIcon>
                <ListItemText primary={_('app.previous')} />
            </ListItem>
        </List>
    )
}
