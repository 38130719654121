import React from 'react'
import { Link as BaseLink } from 'react-router-dom'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import AttachmentIcon from '@material-ui/icons/Attachment'

import config from 'config'
import { useStyles } from './Link.styles'
import { isOnline } from 'helpers'
import { getLang } from 'helpers/lang'

export const Link = ({ item, button, children, className, href }) => {
    const classes = useStyles()
    className = `${className} ${button ? classes.button : classes.root}`

    const internalDomainFound = config.domains.some((domain) =>
        href.includes(domain)
    )
    const internal = internalDomainFound || href.startsWith('/')

    const pdf =
        href.includes(config.pdfUrl) ||
        href.includes(encodeURIComponent(config.pdfUrl)) ||
        href.includes('https://drive.google.com/viewerng/viewer')

    let Component

    if (internal && !pdf) {
        let url = href

        if (internalDomainFound) {
            config.domains.forEach((domain) => {
                url = url.replace(domain, '')
            })
        }

        url = url.replace("/app", `/${getLang()}`)

        Component = (
            <BaseLink className={className} to={url}>
                {children}
            </BaseLink>
        )
    } else {
        Component = (
            <a
                className={className}
                href={href}
                rel="noopener noreferrer"
                target="_blank"
            >
                {!isOnline() && (
                    <LinkOffIcon classes={{ root: classes.icon }} />
                )}
                {children}
                {pdf && <AttachmentIcon classes={{ root: classes.icon }} />}
            </a>
        )
    }

    return Component
}
