import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    desktop: {
        display: 'none',

        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    mobile: {
        display: 'block',
        
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    }
}))