import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    buttonlist: {
        margin: theme.spacing(2, 0),
    },
    button: {
        margin: theme.spacing(0, 1, 1, 0),
        padding: theme.spacing(1, 2),
        textTransform: 'none',
        // fontSize: '14px',
        borderRadius: 100,
        minWidth: '8rem',
        borderWidth: '2px',
        '&:hover': {
            borderWidth: '2px',
        }
    },
}))