import React from 'react'
import { connect } from 'react-redux'

import { Pageheader } from 'components/Pageheader'
import { Layout } from 'components/Layout'
import { Markdown } from 'components/Markdown'
import { Footer } from 'components/Footer'

import { getLoaded, getPage, getPages } from 'modules/page/page.selectors'
import { getSetting } from 'modules/setting/setting.selectors'

import { useStyles } from './Page.styles'

const Content = ({
    name,
    text,
}) => {
    const classes = useStyles()
    return <section className={classes.root}>
        <Pageheader title={name} />
        <Markdown source={text} />
    </section>
}

class PageComponent extends React.Component {

    render() {
        const { selectedPage, settings } = this.props   

        return (
            <Layout title={selectedPage.name} container={true}>
                <Content {...selectedPage} {...this.props} />
                <Footer content={getSetting(settings, 'footer_text')} />
            </Layout>
        )
    }
}



function mapStateToProps(state, router) {
    let { filters, settings } = state
    const { slug } = router.match.params
    
    const pages = getPages(state.pages)
    const loaded = getLoaded(state.pages)
    const post = getPage(state.pages, slug)

    const hasPost = post && post.length > 0

    if (!hasPost && loaded) {
        router.history.push('/not-found')
    }

    const selectedPage = hasPost ? post[0] : false

    return {
        filters,
        pages,
        selectedPage,
        settings,
    }
}

export const Page = connect(mapStateToProps)(PageComponent)
